import { Collapse, Skeleton } from 'antd';

const { Panel } = Collapse;

export const NotesSection = ({
  title,
  children,
}: {
  title?: any;
  children: any;
}) => {
  return (
    <Collapse
      defaultActiveKey={['1']}
      bordered={false}
      expandIconPosition={'end'}
      className="research_collapse"
    >
      <Panel
        header={title || <Skeleton.Input size={'default'} block={true} />}
        key="1"
      >
        {children}
      </Panel>
    </Collapse>
  );
};
