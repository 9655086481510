import {
  Breadcrumb,
  Col,
  Grid,
  message,
  Row,
  Tabs,
  Menu,
  Dropdown,
} from 'antd';

import React, { useEffect, useState } from 'react';
import Integrations from './components/Integrations';
import './settings.less';
import CustomResearch from './components/CustomResearch';
import Notifications from './components/Notifications/Notifications';
import GeneralSettings from './components/General/GeneralSettings';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { fetchAuthSession } from 'aws-amplify/auth';
import { setUserID } from '../store/authSlice';
import { doGet } from '../sdk/Events';
import { Link } from 'react-router-dom';
import { DownOutlined } from '@ant-design/icons';

const Settings: React.FC = () => {
  const dispatch = useDispatch();
  const [tabChange, setTabChange] = useState<string | undefined>(
    'integrations'
  );
  const [generalKey, setGeneralKey] = useState<string>(Date.now().toString());
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const onChange = (key: string) => {
    setTabChange(key);
    // Update the generalKey whenever the General tab is selected
    if (key === 'general') {
      setGeneralKey(Date.now().toString());
    }
  };

  const user_id = useSelector((state: RootState) => state.auth.userID);
  const [integrationStatus, setIntegrationStatus] = useState({
    google: '',
    hubspot: '',
    salesforce: '',
    slack: '',
    slackDisconnectUrl: '',
    microsoft: '',
  });
  const [loading, setLoading] = useState(true);

  const fetchUsersID = async () => {
    setLoading(true);
    const response = await fetchAuthSession();
    const { winrate_id: id = '' } = response?.tokens?.idToken?.payload || {};
    dispatch(setUserID(id));
  };

  const getIntegrationStatus = async () => {
    try {
      const apiPath = `setting/integration/${user_id}`;
      const response = await doGet(apiPath);
      const newIntegrationStatus = response.data.reduce(
        (acc: any, integration: any) => {
          if (Object.prototype.hasOwnProperty.call(acc, integration.provider)) {
            acc[integration.provider] = integration.status;
          }
          if (
            integration.provider === 'slack' &&
            integration.configurationUrl
          ) {
            acc.slackDisconnectUrl = integration.configurationUrl;
          }
          return acc;
        },
        { ...integrationStatus }
      );
      setIntegrationStatus(newIntegrationStatus);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      message.error(error.message);
    }
  };

  useEffect(() => {
    if (user_id) {
      getIntegrationStatus();
    }
  }, [user_id, tabChange]);

  useEffect(() => {
    fetchUsersID();
  }, [tabChange]);

  const items = [
    {
      key: 'integrations',
      label: 'Integrations',
      children: (
        <Integrations
          loading={loading}
          integrationStatus={integrationStatus}
          user_id={user_id}
          getIntegrationStatus={getIntegrationStatus}
        />
      ),
    },
    {
      key: 'custom_research',
      label: 'Custom Research',

      children: <CustomResearch />,
    },

    {
      key: 'general',
      label: 'General',

      children: <GeneralSettings key={generalKey} />,
    },
    {
      key: 'notifications',
      label: 'Notifications',

      children: (
        <Notifications
          integrationStatus={integrationStatus}
          user_id={user_id}
        />
      ),
    },
  ];

  const menu = (
    <Menu
      onClick={(e) => setTabChange(e.key)}
      className="cust_tab_menu"
      items={items.map(({ key, label }) => ({
        key,
        label,
      }))}
    />
  );

  return (
    <div className="settings">
      <Breadcrumb separator=">" className="mb-20">
        <Breadcrumb.Item>
          <Link to="/">My meetings</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Settings</Breadcrumb.Item>
      </Breadcrumb>
      <Row justify={'center'} className="setting_wrapper">
        <Col xs={24} sm={24} lg={24} xl={18}>
          <h2>Settings</h2>
          {screens.md ? (
            <Tabs
              className="mt-24 setting_tabs"
              activeKey={tabChange}
              onChange={(key) => onChange(key)}
              items={items}
            />
          ) : (
            <>
              <Dropdown overlay={menu} className="dropdown-tabs">
                <a
                  onClick={(e) => e.preventDefault()}
                  className="dropdown_tabs"
                >
                  {items.find((item) => item.key === tabChange)?.label ||
                    'Select'}{' '}
                  <DownOutlined />
                </a>
              </Dropdown>
              <div className="tab-content mt-24 setting_tabs">
                {items.find((item) => item.key === tabChange)?.children}
              </div>
            </>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default Settings;
