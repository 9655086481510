import {
  Button,
  Card,
  Col,
  Divider,
  Grid,
  message,
  Row,
  Select,
  Skeleton,
  Switch,
  TimePicker,
  Typography,
} from 'antd';
import React, { useEffect, useState } from 'react';
import Slackicon from 'src/assets/notification-icons/Slack.svg';
import Prepicon from 'src/assets/notification-icons/Prepnotes.svg';
import Group from 'src/assets/notification-icons/Group.svg';

import { EditOutlined } from '@ant-design/icons';
import './notification.less';
import Addnumber from './Addnumber';
import Verify from './Verify';
import Success from './Success';
import Snooze from './Snooze';
import SecondaryButton from '../../../common/SecondaryButton';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { doGet, doPost } from '../../../sdk/Events';
import { connectSlack } from '../../../utils/IntegrationHelper';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

dayjs.extend(utc);

const { Title, Text } = Typography;

interface NotificationProps {
  integrationStatus: any;
  user_id: string;
}

const Notifications: React.FC<NotificationProps> = ({
  integrationStatus,
  user_id,
}) => {
  const [phoneadd, setPhoneadd] = useState(false);
  const [numModal, setnumModal] = useState(false);
  const [success, setSuccessModal] = useState(false);
  const [number, setnumber] = useState('');
  const [snoozemodal, setSnoozemodal] = useState(false);
  const [selectSnoozeDate, setSelectSnoozeDate] = useState(dayjs());
  const [selectSnoozeTime, setSelectSnoozeTime] = useState(dayjs());
  const [selectedKeys, setSelectedKeys] = useState<number[]>([]);
  const [snoozeNotificationType, setSnoozeNotificationType] = useState<
    string[]
  >([]);
  const [phone, setPhone] = useState<string | undefined>('');
  const [getPhoneNumber, setPhoneNumber] = useState<string | undefined>('');
  const [resumeNotification, setResumeNotification] = useState(false);
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  //for enable,disable switch state
  const [enabledState, setEnabledState] = useState<any>({
    email: {
      daily_summary: {
        is_enabled: false,
      },
      meeting_reminder: {
        is_enabled: false,
      },
    },
    slack: {
      meeting_reminder: {
        is_enabled: false,
      },
    },
    text: {
      meeting_reminder: {
        is_enabled: false,
      },
    },
    in_app: {
      meeting_reminder: {
        is_enabled: false,
      },
    },
  });

  //to update the notification state
  const [settings, setSettings] = useState<any>({
    snooze: {
      notification_types: [],
      snooze_until: '',
    },
    email: {
      daily_summary: {
        cron_time: '',
        is_enabled: false,
      },
      meeting_reminder: {
        time_minutes: '',
        is_enabled: false,
      },
    },
    slack: {
      meeting_reminder: {
        time_minutes: '',
        is_enabled: false,
      },
    },
    text: {
      meeting_reminder: {
        time_minutes: '',
        is_enabled: false,
      },
    },
    in_app: {
      meeting_reminder: {
        time_minutes: '',
        is_enabled: false,
      },
      immediate_push: {
        is_enabled: false,
      },
    },
  });

  // Updates notification object based on the provided path and value, then update notification.
  const handleUpdateSetting = async (
    pathOrUpdates: string | { path: string; value: any }[],
    value?: any
  ) => {
    try {
      const updatedSettings = { ...settings };

      const ensurePathExists = (obj: any, keys: string[]) => {
        let current = obj;
        for (let i = 0; i < keys.length - 1; i++) {
          if (!current[keys[i]]) {
            current[keys[i]] = {};
          }
          current = current[keys[i]];
        }
        return current;
      };

      // If a single update is passed
      if (typeof pathOrUpdates === 'string') {
        const keys = pathOrUpdates.split('.');
        const parent = ensurePathExists(updatedSettings, keys);
        parent[keys[keys.length - 1]] = value;
      }
      // If multiple updates are passed
      else if (Array.isArray(pathOrUpdates)) {
        pathOrUpdates.forEach(({ path, value }) => {
          const keys = path.split('.');
          const parent = ensurePathExists(updatedSettings, keys);
          parent[keys[keys.length - 1]] = value;
        });
      }

      setSettings(updatedSettings);

      await handlePostNotification(updatedSettings);
      await handleGetNotification();
    } catch (error) {
      console.error(error);
    }
  };

  //Sends updated notification settings
  const handlePostNotification = async (updatedSettings: any) => {
    try {
      const pathName = 'setting';
      const inputData = {
        feature: 'notification',
        user_id: user_id,
        notificationSettings: updatedSettings,
      };
      await doPost(pathName, inputData);
      message.success('Notification updated successfully');
    } catch (error) {
      console.error(error);
    }
  };
  const [notificationLoader, setNotificationLoader] = useState(true);
  // Fetches the notification settings and updates the state with the retrieved data.
  const handleGetNotification = async () => {
    try {
      const pathName = `setting/notification/${user_id}`;
      const response = await doGet(pathName);
      if (response?.data?.length) {
        const getNotificatonData = response?.data?.[0]?.notifications;
        setSettings(getNotificatonData);
        const snoozeTime =
          getNotificatonData?.snooze?.snooze_until?.split('T')[1];
        const snoozeDate =
          getNotificatonData?.snooze?.snooze_until?.split('T')[0];
        setSelectSnoozeDate(dayjs(snoozeDate));
        setSelectSnoozeTime(
          snoozeTime ? dayjs(snoozeTime, 'HH:mm:ss') : dayjs() // Set current time if snoozeTime is null
        );
        setSnoozeNotificationType(
          getNotificatonData?.snooze?.notification_types
        );
        setEnabledState({
          email: {
            daily_summary: {
              is_enabled: getNotificatonData.email.daily_summary.is_enabled,
            },
            meeting_reminder: {
              is_enabled: getNotificatonData.email.meeting_reminder.is_enabled,
            },
          },
          slack: {
            meeting_reminder: {
              is_enabled: getNotificatonData.slack.meeting_reminder.is_enabled,
            },
          },
          text: {
            meeting_reminder: {
              is_enabled: getNotificatonData.text.meeting_reminder.is_enabled,
            },
          },
          in_app: {
            meeting_reminder: {
              is_enabled: getNotificatonData.in_app.meeting_reminder.is_enabled,
            },
          },
        });
      }
      setNotificationLoader(false);
    } catch (err) {
      setNotificationLoader(false);
      console.log(err);
    }
  };

  // Updates the `is_enabled` field in the enabledState based on the provided path and value.
  const updateEnabledState = (path: string, value: boolean) => {
    setEnabledState((prevState: any) => {
      const keys = path.split('.');
      const lastKey = keys.pop() as string;
      const updatedState = { ...prevState };

      let current = updatedState;
      for (const key of keys) {
        current[key] = { ...current[key] };
        current = current[key];
      }

      // Update the `is_enabled` field
      current[lastKey] = { ...current[lastKey], is_enabled: value };

      return updatedState;
    });
  };

  const getUsersInfo = async () => {
    try {
      const response = await doGet('user');
      setPhone(response?.data?.phone);
      setPhoneNumber(response?.data?.phone);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    if (user_id) {
      handleGetNotification();
    }
  }, [user_id]);

  useEffect(() => {
    getUsersInfo();
  }, []);

  const handlesnooze = () => {
    setSnoozemodal(true);
  };

  const handleResumeNotifications = async () => {
    try {
      setResumeNotification(true);
      handleUpdateSetting([
        { path: 'snooze.snooze_until', value: null },
        {
          path: 'snooze.notification_types',
          value: [],
        },
      ]);
      await handleGetNotification();
      message.success('Notification resume successfully');
      setResumeNotification(false);
    } catch (err) {
      setResumeNotification(false);
    }
  };

  const formatPhoneNumber = (phoneNumber: any): string => {
    try {
      const parsedNumber = parsePhoneNumberFromString(phoneNumber);
      return parsedNumber ? parsedNumber.formatInternational() : phoneNumber;
    } catch (error) {
      console.error('Invalid phone number:', phoneNumber);
      return phoneNumber;
    }
  };

  return (
    <div className="notification">
      <Row className="notification-container" style={{ marginTop: '30px' }}>
        <Col span={24}>
          {integrationStatus.slack === 'disconnected' && (
            <Card className="notification-card">
              <Row className="notification-header">
                <div className="notification-content">
                  <img src={Slackicon} alt="image" />
                  <Text className="notification-text">
                    Get your Prep Notes directly on Slack
                  </Text>
                </div>
                <Button
                  type="link"
                  className={
                    !screens.md
                      ? 'notification-button notification-margin'
                      : 'notification-button'
                  }
                  onClick={() => connectSlack(user_id, 'notification')}
                >
                  Connect Slack
                </Button>
              </Row>
            </Card>
          )}
          {!getPhoneNumber && (
            <Card className="notification-card mt-10">
              <Row className="notification-header">
                <div className="notification-content">
                  <img src={Prepicon} alt="image" />
                  <Text className="notification-text">
                    Get text alerts with your Prep Notes
                  </Text>
                </div>
                <Button
                  type="link"
                  className={
                    !screens.md
                      ? 'notification-button notification-margin'
                      : 'notification-button'
                  }
                  onClick={() => setnumModal(true)}
                >
                  Add Number
                </Button>
              </Row>
            </Card>
          )}
        </Col>

        {snoozeNotificationType?.length > 3 && (
          <Card className="notification-card">
            <Row className="notification-header">
              <div className="notification-content">
                <img src={Group} alt="image" />
                <Text className="notification-text">
                  {` Your notifications are currently snoozed until  ${
                    !resumeNotification
                      ? dayjs(settings?.snooze?.snooze_until).format(
                          'MMM DD, h:mm A'
                        )
                      : ''
                  }`}
                </Text>
              </div>
              <Button
                loading={resumeNotification}
                type="link"
                className={
                  !screens.md
                    ? 'notification-button notification-margin'
                    : 'notification-button'
                }
                onClick={handleResumeNotifications}
              >
                {'Resume Notification'}
              </Button>
            </Row>
          </Card>
        )}

        <Row className="notification-details">
          {/*Email*/}
          <Col className="notification-col" key={2}>
            <Title level={5}>Email</Title>
            <Col className="notification-item">
              <div className="notification-item-content">
                <Text>
                  Get an email each morning with all of today’s prep notes
                </Text>
                {notificationLoader ? (
                  <Skeleton.Input active />
                ) : (
                  <TimePicker
                    minuteStep={15}
                    disabled={
                      !enabledState.email.daily_summary.is_enabled ||
                      snoozeNotificationType?.includes('email')
                    }
                    className="time-picker"
                    format="h:mm A"
                    allowClear={false}
                    onChange={(_time: any, timeString: any) =>
                      handleUpdateSetting(
                        'email.daily_summary.cron_time',
                        timeString
                      )
                    }
                    value={dayjs(
                      settings?.email?.daily_summary?.cron_time,
                      'h:mm A'
                    )}
                  />
                )}
              </div>
              <Switch
                disabled={snoozeNotificationType?.includes('email')}
                onChange={(e) => {
                  {
                    handleUpdateSetting('email.daily_summary.is_enabled', e);
                    updateEnabledState('email.daily_summary.is_enabled', e);
                  }
                }}
                value={settings?.email.daily_summary.is_enabled}
              />
            </Col>
            <Col span={24} className="reuse-item">
              <div className="reuse-text">
                <Text>
                  Get timely email notifications with prep notes before each
                  meeting
                </Text>
                {notificationLoader ? (
                  <Skeleton.Input active />
                ) : (
                  <Select
                    disabled={
                      !enabledState.email.meeting_reminder.is_enabled ||
                      snoozeNotificationType?.includes('email')
                    }
                    value={settings?.email.meeting_reminder.time_minutes}
                    onChange={(e) =>
                      handleUpdateSetting(
                        'email.meeting_reminder.time_minutes',
                        e
                      )
                    }
                    options={[
                      { label: '1 hour before', value: 60 },
                      { label: '30 mins before', value: 30 },
                      { label: '15 mins before', value: 15 },
                    ]}
                    placeholder="Select Reminder Time"
                    style={{ width: 200 }} // Optional: adjust the width as needed
                  />
                )}
              </div>
              <Switch
                onChange={(e) => {
                  handleUpdateSetting('email.meeting_reminder.is_enabled', e);
                  updateEnabledState('email.meeting_reminder.is_enabled', e);
                }}
                value={settings.email.meeting_reminder.is_enabled}
                disabled={snoozeNotificationType?.includes('email')}
              />
            </Col>
          </Col>
          <Divider />

          {/*Slack*/}
          {integrationStatus.slack === 'connected' && (
            <Col className="notification-slack" key={3}>
              <Col className="notification-col">
                <Title level={5}>Slack</Title>
                <Col span={24} className="reuse-item">
                  <div className="reuse-text">
                    <Text>
                      Get your prep notes directly in Slack for quick, in-app
                      reference.
                    </Text>
                    {notificationLoader ? (
                      <Skeleton.Input active />
                    ) : (
                      <Select
                        disabled={
                          !enabledState.slack.meeting_reminder.is_enabled ||
                          snoozeNotificationType?.includes('slack')
                        }
                        value={settings.slack.meeting_reminder.time_minutes}
                        onChange={(e) =>
                          handleUpdateSetting(
                            'slack.meeting_reminder.time_minutes',
                            e
                          )
                        }
                        options={[
                          { label: '1 hour before', value: 60 },
                          { label: '30 mins before', value: 30 },
                          { label: '15 mins before', value: 15 },
                        ]}
                        placeholder="Select Reminder Time"
                        style={{ width: 200 }} // Optional: adjust the width as needed
                      />
                    )}
                  </div>
                  <Switch
                    onChange={(e) => {
                      handleUpdateSetting(
                        'slack.meeting_reminder.is_enabled',
                        e
                      );
                      updateEnabledState(
                        'slack.meeting_reminder.is_enabled',
                        e
                      );
                    }}
                    disabled={snoozeNotificationType?.includes('slack')}
                    value={settings.slack.meeting_reminder.is_enabled}
                  />{' '}
                  {/* Apply disabled state */}
                </Col>
              </Col>
              <Divider className="divider-margin" />
            </Col>
          )}

          {/*Text*/}
          {getPhoneNumber && (
            <Col className="notification-text-col" key={4}>
              <Col className="notification-col">
                <div className="notification-header-text">
                  <Title level={5}>Text</Title>
                  <div>
                    <Text className="formatted-number mr-10">
                      {formatPhoneNumber(getPhoneNumber)}
                    </Text>
                    <Divider type="vertical" />
                    <EditOutlined
                      onClick={() => {
                        setnumModal(true);
                        setPhone(getPhoneNumber);
                      }}
                      className="ml-10"
                    />
                  </div>
                </div>
                <Col span={24} className="reuse-item">
                  <div className="reuse-text">
                    <Text>
                      Receive text alerts with your prep notes when you’re on
                      the go.
                    </Text>
                    {notificationLoader ? (
                      <Skeleton.Input active />
                    ) : (
                      <Select
                        disabled={
                          !enabledState?.text?.meeting_reminder?.is_enabled ||
                          snoozeNotificationType?.includes('text')
                        }
                        value={settings.text.meeting_reminder.time_minutes}
                        onChange={(e) =>
                          handleUpdateSetting(
                            'text.meeting_reminder.time_minutes',
                            e
                          )
                        }
                        options={[
                          { label: '1 hour before', value: 60 },
                          { label: '30 mins before', value: 30 },
                          { label: '15 mins before', value: 15 },
                        ]}
                        placeholder="Select Reminder Time"
                        style={{ width: 200 }} // Optional: adjust the width as needed
                      />
                    )}
                  </div>
                  <Switch
                    onChange={(e) => {
                      handleUpdateSetting(
                        'text.meeting_reminder.is_enabled',
                        e
                      );
                      updateEnabledState('text.meeting_reminder.is_enabled', e);
                    }}
                    value={settings?.text?.meeting_reminder.is_enabled}
                    disabled={
                      snoozeNotificationType?.includes('text') ? true : false
                    }
                  />
                  {/* Apply disabled state */}
                </Col>
              </Col>
              <Divider className="divider-margin" />
            </Col>
          )}

          {/*In-App*/}
          {/* <Col className="notification-col" key={5}>
            <Title level={5}>In-App</Title>
            <Col span={24} className="reuse-item">
              <div className="reuse-text">
                <Text>
                  Get real-time alerts directly within the app with prep notes
                  before each meeting
                </Text>
                {notificationLoader ? (
                  <Skeleton.Input active />
                ) : (
                  <Select
                    disabled={
                      !enabledState.in_app.meeting_reminder.is_enabled ||
                      snoozeNotificationType?.includes('in_app')
                    }
                    value={settings.in_app.meeting_reminder.time_minutes}
                    onChange={(e) =>
                      handleUpdateSetting(
                        'in_app.meeting_reminder.time_minutes',
                        e
                      )
                    }
                    options={[
                      { label: '1 hour before', value: 60 },
                      { label: '30 mins before', value: 30 },
                      { label: '15 mins before', value: 15 },
                    ]}
                    placeholder="Select Reminder Time"
                    style={{ width: 200 }} // Optional: adjust the width as needed
                  />
                )}
              </div>
              <Switch
                onChange={(e) => {
                  handleUpdateSetting('in_app.meeting_reminder.is_enabled', e);
                  updateEnabledState('in_app.meeting_reminder.is_enabled', e);
                }}
                value={settings.in_app.meeting_reminder.is_enabled}
                disabled={
                  snoozeNotificationType?.includes('in_app') ? true : false
                }
              />{' '}
            </Col> 
            <Col className="notification-item">
              <Text>Desktop push notification immediately on your desktop</Text>
              <Switch
                disabled={snoozeNotificationType?.includes('in_app')}
                onChange={(e) =>
                  handleUpdateSetting('in_app.immediate_push.is_enabled', e)
                }
                value={settings.in_app.immediate_push.is_enabled}
              />
            </Col>
          </Col> */}
          {/* <Divider /> */}

          {/*Need a break*/}
          <Col className="notification-col">
            <Title level={5}>Need a break?</Title>
            <Row className="notification-item" justify="space-between">
              <Col md={19} xs={24} sm={24} lg={19} xl={19}>
                <Text>
                  Snooze all alerts for a set time. Your notifications will
                  start again automatically when the snooze period ends.
                </Text>
              </Col>
              <Col
                md={5}
                xs={24}
                sm={24}
                lg={5}
                xl={5}
                className={!screens.md ? 'mt-10' : 'snooze-btn'}
              >
                <SecondaryButton
                  disabled={snoozeNotificationType?.length > 3}
                  text={'Snooze Notifications'}
                  className="snooze-button"
                  onClick={handlesnooze}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Row>

      {numModal && (
        <Addnumber
          onClose={() => setnumModal(false)}
          setPhoneadd={setPhoneadd}
          setnumModal={setnumModal}
          setnumber={setnumber}
          user_id={user_id}
          setPhone={setPhone}
          phone={phone}
        />
      )}
      {phoneadd && (
        <Verify
          onClose={() => setPhoneadd(false)}
          setPhoneadd={setPhoneadd}
          setSuccessModal={setSuccessModal}
          number={number}
          user_id={user_id}
          formatPhoneNumber={formatPhoneNumber}
          getUsersInfo={getUsersInfo}
        />
      )}
      {success && <Success onClose={() => setSuccessModal(false)} />}
      {snoozemodal && (
        <Snooze
          onClose={() => setSnoozemodal(false)}
          setSelectSnoozeDate={setSelectSnoozeDate}
          selectSnoozeDate={selectSnoozeDate}
          setSelectSnoozeTime={setSelectSnoozeTime}
          selectSnoozeTime={selectSnoozeTime} // Convert to string
          handleUpdateSetting={handleUpdateSetting}
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          settings={settings}
          integrationStatus={integrationStatus}
          getPhoneNumber={getPhoneNumber}
        />
      )}
    </div>
  );
};

export default Notifications;
