import React, { useEffect, useState } from 'react';
import {
  Button,
  Calendar,
  Divider,
  Menu,
  Row,
  Skeleton,
  Typography,
} from 'antd';
import type { Dayjs } from 'dayjs';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

import dayjs from 'dayjs';
import { RootState } from '../store/store';
import { useSelector, useDispatch } from 'react-redux';
import {
  setSelectedMonth,
  setSelectedDate,
  setDateChange,
  setStartDate,
  setEndDate,
  setprepnoteSelectedDate,
} from '../store/authSlice';
import { createMeetingNotes, doGet } from '../sdk/Events';
import { fetchAuthSession } from 'aws-amplify/auth';
import _ from 'lodash';
import { WinrateImages } from '../assets/Images';
import { fetchProvider, formatTimeToLocal } from '../common/CommonMethod';
import { useNavigate } from 'react-router-dom';
import { ssoType } from '../common/Utils';

interface NavigationProps {
  collapsed?: boolean;
}

const NavigationMenu: React.FC<NavigationProps> = () => {
  const url = new URL(window.location.href);
  const summaryId = url.pathname.split('/')[2];
  const [selectedSummaryId, setSelectedSummaryId] = useState();
  const [loading, setLoading] = useState(false);
  const dateChange = useSelector((state: RootState) => state.auth.dateChange);
  const selectedMonth = useSelector(
    (state: RootState) => state.auth.selectedMonth
  );
  const isCalenderVisible = useSelector(
    (state: RootState) => state.auth.isCalenderVisible
  );

  const selectPrepNoteDate = useSelector(
    (state: RootState) => state.auth.prepnoteSelectedDate
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { noMettingIcon } = WinrateImages.EventIcons;

  const onPrevMonth = () => {
    dispatch(setSelectedMonth(selectedMonth.subtract(1, 'month')));
  };

  const onNextMonth = () => {
    dispatch(setSelectedMonth(selectedMonth.add(1, 'month')));
  };
  interface User {
    email: string;
    id: string;
  }
  interface Event {
    start_time: string;
    end_time: string;
    summary: string;
    event_summary_id: string | null;
    // Add other properties as needed
  }
  interface EventsResponse {
    meetings: Event[];
  }

  const [users, setUsers] = useState<User | undefined>(undefined);

  const fetchUsersID = async () => {
    const response = await fetchAuthSession();
    const { email = '', winrate_id: id = '' }: any =
      response?.tokens?.idToken?.payload || {};
    setUsers({ email, id });
  };
  const [meetingData, setMeetingData] = useState<EventsResponse | undefined>(
    undefined
  );

  const [connectedProviders, setConnectedProviders] = useState<string[]>([]);

  const fetchData = async () => {
    try {
      const connected = await fetchProvider(users?.id as string);
      setConnectedProviders(connected);
    } catch (err) {
      console.error('Failed to fetch connected providers:', err);
    }
  };

  const fetchCalendarEvents = async () => {
    setLoading(true);
    try {
      // Set timeMin to today's start in UTC (00:00:00)

      const today = new Date();
      today.setUTCHours(0, 0, 0, 0);
      const timeMin = today.toISOString().split('.')[0] + 'Z';
      // Prepare input data for fetching calendar events

      // Set timeMax to the end of the week in UTC (23:59:59)
      const endOfToday = new Date(today);
      endOfToday.setUTCHours(23, 59, 59, 999);
      const timeMax = endOfToday.toISOString().split('.')[0] + 'Z';

      const calendarDate = dateChange ? selectPrepNoteDate.toDate() : '';
      const calendarEndDate = dateChange ? selectPrepNoteDate.toDate() : '';
      if (calendarDate) calendarDate.setUTCHours(0, 0, 0, 0);

      if (calendarEndDate) calendarEndDate.setUTCHours(23, 59, 59, 999);

      const timeMinimun = calendarDate ? calendarDate.toISOString() : timeMin;
      const timeMaximum = calendarEndDate
        ? calendarEndDate.toISOString()
        : timeMax;
      const userId = _.get(users, 'id');
      const domain = _.get(users, 'email', '').split('@')[1];
      let path = '';
      // Fetch calendar events using the inputData
      path = `events?timeMin=${timeMinimun}&timeMax=${timeMaximum}&userId=${userId}&domain=${domain}&provider=${connectedProviders}`;
      if (connectedProviders.includes(ssoType.microsoft)) {
        const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        path += `&timezone=${encodeURIComponent(userTimeZone)}`;
      }
      const response = await doGet(path);
      setMeetingData(response.data);

      setLoading(false);
      // Reset states if there was a date change
    } catch (err: any) {
      setLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    fetchUsersID();
  }, []);
  useEffect(() => {
    if (
      users &&
      window.location.pathname.includes('/note') === true &&
      connectedProviders?.length > 0
    ) {
      fetchCalendarEvents();
    }
  }, [users, selectPrepNoteDate, window.location.pathname, connectedProviders]);

  useEffect(() => {
    if (users) {
      fetchData();
    }
  }, [users]);

  // Handle next date click
  const onNextDate = () => {
    dispatch(setDateChange(true));
    dispatch(setprepnoteSelectedDate(selectPrepNoteDate.add(1, 'day')));
  };

  // Handle previous date click
  const onPreviousDate = () => {
    dispatch(setDateChange(true));
    dispatch(setprepnoteSelectedDate(selectPrepNoteDate.subtract(1, 'day')));
  };

  const fetchTodayDate = () => {
    dispatch(setprepnoteSelectedDate(dayjs()));
  };
  const formatDate = () => {
    const today = dayjs();
    return selectPrepNoteDate.isSame(today, 'day')
      ? 'Today'
      : selectPrepNoteDate.format('MMM DD, YYYY');
  };

  const onSelectDate = (date: Dayjs) => {
    dispatch(setStartDate(''));
    dispatch(setEndDate(''));
    dispatch(setDateChange(true));
    dispatch(setSelectedDate(date));
    dispatch(setSelectedMonth(date));
    dispatch(setprepnoteSelectedDate(date));
  };

  const headerRender = () => {
    const month = dayjs(selectedMonth).format('MMMM');
    const year = dayjs(selectedMonth).format('YYYY');

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
        className="mb-10"
      >
        <span className="ml-10 text-primary fw-500">
          {month} {year}
        </span>
        <div className="mr-10">
          <LeftOutlined onClick={onPrevMonth} className="change-icons mr-10" />
          <RightOutlined onClick={onNextMonth} className="change-icons" />
        </div>
      </div>
    );
  };
  const generateMeetingThenRedirect = async (event: any) => {
    if (event.event_summary_id) {
      navigate(`/notes/${event.event_summary_id}`);
      return;
    }
    // setCreateLoader(event.meeting_id);
    const domains = event.external_domains.map(
      (email: string) => email.split('@')[1]
    );
    const domain = domains?.[0];
    const payload = {
      start_time: event.start_time,
      end_time: event.end_time,
      description: event.summary,
      summary: event.summary,
      attendees: event.attendees.map((attendee: any) => attendee.email),
      organizer: event.organizer?.email,
      meeting_id: event.meeting_id,
      domain,
    };
    const response = await createMeetingNotes(payload);
    if (response && response?.data?.summaryId) {
      setSelectedSummaryId(response?.data?.summaryId);
      navigate(`/notes/${response?.data?.summaryId}`);
    }
  };
  return (
    <Menu mode="inline" theme="dark">
      <div className="mt-20 ml-10 mr-10">
        {isCalenderVisible && (
          <Calendar
            className="calendar-view"
            fullscreen={false}
            value={selectedMonth}
            onSelect={onSelectDate}
            headerRender={headerRender}
          />
        )}
      </div>
      {window.location.pathname.includes('/notes') && (
        <>
          {isCalenderVisible && (
            <div>
              <div>
                <Row justify="space-between" className="pl-20 pr-20 mt-20">
                  <Typography.Text className="fw-500">
                    {formatDate()}
                  </Typography.Text>
                  <div className="today-calendar">
                    <LeftOutlined
                      className="change-icons mr-5 text-primary"
                      onClick={onPreviousDate}
                    />
                    <Button
                      type="text"
                      className="p-0"
                      onClick={fetchTodayDate}
                      icon={<img src={WinrateImages.EventIcons.calendarIcon} />}
                    />

                    <RightOutlined
                      className="change-icons text-primary ml-5"
                      onClick={onNextDate}
                    />
                  </div>
                </Row>
              </div>
              {loading ? (
                <Skeleton active className="pl-20 pr-20" />
              ) : meetingData?.meetings?.length ? (
                meetingData?.meetings.map((item, index) => {
                  const isHighlighted =
                    item.event_summary_id || selectedSummaryId;
                  return (
                    <div
                      key={index}
                      className={
                        isHighlighted === summaryId
                          ? 'event-details'
                          : 'event-details padding-23'
                      }
                      onClick={() => generateMeetingThenRedirect(item)}
                    >
                      {' '}
                      {isHighlighted === summaryId && (
                        <Divider type="vertical" className="event-divider" />
                      )}
                      <div className="event-content">
                        <Row>
                          <Typography.Text
                            className={
                              isHighlighted === summaryId
                                ? 'branding-color fw-500'
                                : 'text-primary '
                            }
                          >
                            {formatTimeToLocal(item.start_time)} to{' '}
                            {formatTimeToLocal(item.end_time)}{' '}
                          </Typography.Text>
                        </Row>
                        <Row>
                          <Typography.Text
                            className={
                              isHighlighted === summaryId
                                ? 'branding-color fw-500'
                                : 'text-primary '
                            }
                            style={
                              // eslint-disable-next-line no-constant-condition
                              true
                                ? {
                                    width: 200,
                                  }
                                : undefined
                            }
                            ellipsis={
                              // eslint-disable-next-line no-constant-condition
                              true
                                ? {
                                    tooltip: item?.summary,
                                  }
                                : false
                            }
                          >
                            {item?.summary}
                          </Typography.Text>
                        </Row>
                      </div>
                    </div>
                  );
                })
              ) : (
                <Row justify="space-between" className="no-meeting-text">
                  <img src={noMettingIcon} alt="" />
                  <Typography.Text className="fw-500">
                    No meetings found for <br /> {formatDate()}
                  </Typography.Text>
                </Row>
              )}
            </div>
          )}
        </>
      )}
    </Menu>
  );
};

export default NavigationMenu;
