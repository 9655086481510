import {
  CloseOutlined,
  EditOutlined,
  InfoCircleOutlined,
  PlusCircleFilled,
} from '@ant-design/icons';
import { Button, Col, Row, Space, Tooltip, Typography } from 'antd';
import { Input, message } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { doPost, doPut, doGet } from '../../../sdk/Events';
import { useDispatch, useSelector } from 'react-redux';
import {
  setisResearchAdded,
  setisFirstResearch,
} from '../../../store/customResearchSlice';
import { LinkItem, BegginingResearchItemProps } from './CustomResearchTypes';
import { RootState } from 'store/store';
import SubscriptionExpiredModal from '../../../MyAccount/components/Subscription/SubscriptionExpiredModal';
import TagIcon from '../../../assets/icons/tag-icon.svg';
const { TextArea } = Input;

const BegginingResearchItem: React.FC<BegginingResearchItemProps> = ({
  isModal,
  setIsResearchModalOpen,
  editingItem,
  customResearchs,
  isCancelEditModal,
}) => {
  const [title, setTitle] = useState<string>('');
  const [mainComment, setMainComment] = useState<string>('');
  const [links, setLinks] = useState<LinkItem[]>([]);
  const [loading, setLoading] = useState(false);
  const [showEndSubscriptionModal, setShowEndSubscriptionModal] =
    useState(false);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const storedUserDetails = localStorage.getItem('UserDetails');
  const userDetails = storedUserDetails ? JSON.parse(storedUserDetails) : null;
  const paymentStatus = useSelector(
    (state: RootState) => state.auth.payment_status
  );
  const dispatch = useDispatch();

  const predefinedTexts = [
    {
      title: 'Attendee Backgrounds',
      description: "Tell me more about the attendees' backgrounds",
    },
    {
      title: 'Competitor Summary',
      description: 'Summarize notable competitors',
    },
    {
      title: 'Recent Challenges',
      description: 'Provide insights on recent challenges',
    },
  ];

  const handlePredefinedTextClick = (title: string, description: string) => {
    setMainComment(description);
    setTitle(title);
  };

  useEffect(() => {
    if (editingItem) {
      setTitle(editingItem.title || '');
      setMainComment(editingItem.prompt);
      setLinks(
        editingItem.sources.links.map((link, index) => ({
          id: index,
          text: link,
          isEditing: false,
        }))
      );
    } else {
      setTitle('');
      setMainComment('');
      setLinks([]);
    }
  }, [editingItem]);
  const isValidURL = (string: string) => {
    const regex = /^(https?:\/\/)?([\da-z.-]+)\.[a-z]{2,6}(\/[\w .-]*)*\/?$/;
    return regex.test(string);
  };
  const addLink = () => {
    const lastLink = links[links.length - 1];
    if (lastLink && !lastLink.text.trim()) {
      message.error('Link text cannot be empty!');
      return;
    }
    if (lastLink && lastLink.text.trim() && !isValidURL(lastLink.text.trim())) {
      message.error('Please enter a valid URL!');
      return;
    }
    setLinks([...links, { id: Date.now(), text: '', isEditing: true }]);
  };
  const deleteLink = (id: number) => {
    setLinks(links.filter((link) => link.id !== id));
  };
  const toggleEditLink = (id: number) => {
    setLinks(
      links.map((link) =>
        link.id === id ? { ...link, isEditing: !link.isEditing } : link
      )
    );
  };
  const updateLinkText = (id: number, value: string) => {
    setLinks(
      links.map((link) => (link.id === id ? { ...link, text: value } : link))
    );
  };
  const handleBlur = (id: number) => {
    setLinks((prevLinks) =>
      prevLinks.map((link) => {
        if (link.id === id) {
          const isEditing = link.text.trim() === '' ? true : false;
          return { ...link, isEditing };
        }
        return link;
      })
    );
    const currentLink = links.find((link) => link.id === id);
    if (currentLink && !isValidURL(currentLink.text.trim())) {
      message.error('Please enter a valid URL!');
    }
  };

  interface Block {
    title: string;
    is_hidden: boolean;
    identifier: string;
    type?: string;
  }

  const createOrUpdateResearchItem = async () => {
    setLoading(true);
    if (!title.trim()) {
      message.error('Please add a title.');
      setLoading(false);
      return;
    }
    if (!mainComment) {
      message.error('Please provide a valid prompt.');
      setLoading(false);
      return;
    }
    const invalidLinks = links.filter((link) => !isValidURL(link.text.trim()));
    if (invalidLinks.length > 0) {
      message.error('Please enter valid URLs for all links.');
      setLoading(false);
      return;
    }
    const emptyLink = links.find((link) => !link.text.trim());
    if (emptyLink) {
      message.error('Link text cannot be empty.');
      setLoading(false);
      return;
    }

    let newResearchItem;
    if (editingItem) {
      newResearchItem = {
        feature: 'custom_research',
        prompt_id: editingItem?.id,
        id: editingItem?.id,
        title,
        prompt: mainComment,
        sources: {
          links: links.map((link) => link.text).filter(Boolean),
        },
      };
    } else {
      newResearchItem = {
        feature: 'custom_research',
        user_id: userDetails?.userInfo?.id,
        organization_id: userDetails?.organization_id,
        title,
        prompt: mainComment,
        sources: {
          links: links.map((link) => link.text).filter(Boolean),
        },
      };
    }

    const path = `setting`;
    try {
      const response = editingItem
        ? await doPut(path, newResearchItem)
        : await doPost(path, newResearchItem);

      if (response) {
        if (setIsResearchModalOpen) {
          setIsResearchModalOpen(false);
        }
        if (customResearchs?.length === 0 && !editingItem) {
          dispatch(setisFirstResearch(true));
        }
        dispatch(setisResearchAdded(true));
        setMainComment('');
        setLinks([]);
        message.success(
          editingItem
            ? 'Custom Research Updated Successfully'
            : 'Custom Research Added Successfully'
        );

        const generalSettingPath = `setting/general/${userDetails?.userInfo?.id}`;
        try {
          const generalResponse = await doGet(generalSettingPath);
          if (generalResponse) {
            const { blocks, prep_notes } = generalResponse.data[0].preferences;
            let updatedBlocks: Block[];
            if (editingItem) {
              updatedBlocks = blocks.map((block: any) =>
                block.identifier === editingItem.id
                  ? { ...block, title: title }
                  : block
              );
            } else {
              const newBlock = {
                title,
                is_hidden: false,
                identifier: response?.data?.id,
                type: 'custom_research',
              };
              updatedBlocks = [...blocks, newBlock];
            }
            console.log('updatedBlocks', updatedBlocks);

            const updateGeneralSettings = async (updatedBlocks: Block[]) => {
              const payload = {
                feature: 'general',
                user_id: userDetails?.userInfo?.id,
                preferences: {
                  blocks: updatedBlocks.map(
                    ({ title, is_hidden, identifier, type }) => ({
                      title,
                      is_hidden,
                      identifier,
                      type,
                    })
                  ),
                  prep_notes: {
                    internal_meeting: prep_notes?.internal_meeting ?? false,
                    external_meeting: prep_notes?.external_meeting ?? true,
                    timing: prep_notes?.timing || {},
                  },
                },
              };
              try {
                const response = await doPost(path, payload);
                if (response) {
                  console.log('General Settings Updated Successfully');
                } else {
                  message.error('General Settings Update Failed');
                }
              } catch (error) {
                message.error(
                  'An error occurred while updating General Settings.'
                );
                console.error('Error:', error);
              }
            };

            await updateGeneralSettings(updatedBlocks);
          }
        } catch (error) {
          console.error('Error:', error);
        }
      } else {
        message.error(
          editingItem
            ? 'Failed To Update Custom Research.'
            : 'Failed To Create Custom Research.'
        );
      }
    } catch (error) {
      message.error(
        editingItem
          ? 'An error occurred while updating the Custom Research.'
          : 'An error occurred while creating the Custom Research.'
      );
      dispatch(setisResearchAdded(false));
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setMainComment(editingItem?.prompt ?? '');
    setTitle(editingItem?.title ?? '');
    if (!editingItem) {
      setLinks([]);
    } else {
      setLinks(
        editingItem.sources.links.map((link, index) => ({
          id: index,
          text: link,
          isEditing: false,
        }))
      );
    }
  }, [isCancelEditModal]);
  return (
    <>
      <Row>
        <Col span={24}>
          <div className="custom_research_add_item title-font">
            {!isModal ? (
              <>
                <Typography.Title level={5} className="title">
                  Start Your Custom Research
                </Typography.Title>
                <Typography.Paragraph className="description">
                  Enter a question or topic, guiding Winrate AI on what you’re
                  looking to explore
                </Typography.Paragraph>
              </>
            ) : (
              <Typography.Title level={5} className="title">
                {editingItem ? 'Edit Research' : 'New Research'}
              </Typography.Title>
            )}
          </div>
          <div className="custom_research_beginning_item">
            <Input
              placeholder="Enter Research Title"
              value={title}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setTitle(e.target.value)
              }
              className="custom-textfield"
              style={{ marginBottom: '12px' }}
              showCount
              maxLength={64}
            />
            <TextArea
              placeholder="Add Custom Research Description"
              ref={textAreaRef}
              style={{ height: 75, resize: 'none' }}
              value={mainComment}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                setMainComment(e.target.value)
              }
              className="custom-textarea"
              maxLength={200}
              showCount
            />
            <div className="custom_tag_container">
              {predefinedTexts.map((item, index) => (
                <div
                  key={index}
                  onClick={() =>
                    handlePredefinedTextClick(item.title, item.description)
                  }
                  className="custom_research_tags"
                >
                  <img src={TagIcon} alt="Tag Icon" />
                  {item.description}
                </div>
              ))}
            </div>
            <div className="custom_link_container">
              {links.map((link) => (
                <Space key={link.id} className="add_link_item">
                  <Input
                    value={link.text}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      updateLinkText(link.id, e.target.value)
                    }
                    placeholder="Enter or update the URL"
                    className="input_link"
                    onBlur={() => handleBlur(link.id)}
                    readOnly={!link.isEditing}
                    style={{
                      cursor: link.isEditing ? 'text' : 'pointer',
                      color: !link.isEditing ? '#0b8751' : '#141414',
                      backgroundColor: !link.isEditing
                        ? 'rgba(0, 0, 0, 0.04)'
                        : '#fff',
                    }}
                    onClick={() => {
                      if (!link.isEditing && link.text) {
                        window.open(link.text, '_blank', 'noopener,noreferrer');
                      }
                    }}
                  />
                  {link.text && !link.isEditing ? (
                    <EditOutlined
                      className="edit_link"
                      onClick={() => toggleEditLink(link.id)}
                    />
                  ) : null}
                  <CloseOutlined
                    onClick={() => deleteLink(link.id)}
                    className="delete_link"
                  />
                </Space>
              ))}
            </div>

            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginTop: '12px',
                padding: '5px 0',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: '12px',
                  padding: '5px 0',
                }}
              >
                <Typography.Title
                  level={5}
                  className="title"
                  onClick={addLink}
                  style={{ cursor: 'pointer', marginBottom: 0 }}
                >
                  <PlusCircleFilled
                    style={{
                      color: '#0b8751',
                      marginRight: '8px',
                      cursor: 'pointer',
                    }}
                  />
                  Add Link
                </Typography.Title>
                <span>
                  <Tooltip
                    placement="bottomLeft"
                    title="Get automated targeted insights
by adding any link"
                  >
                    <InfoCircleOutlined
                      style={{
                        color: '#141414',
                        marginLeft: '8px',
                        fontSize: '12px',
                        cursor: 'pointer',
                      }}
                    />
                  </Tooltip>
                </span>
              </div>
              {!title && !mainComment && links.length === 0 ? (
                <Button
                  className="disabled"
                  disabled
                  onClick={() =>
                    (paymentStatus === 'expired' ||
                      paymentStatus === 'ended') &&
                    setShowEndSubscriptionModal(true)
                  }
                >
                  Save Research
                </Button>
              ) : (
                <Button
                  className="settings_action_btn"
                  onClick={() =>
                    paymentStatus === 'expired' || paymentStatus === 'ended'
                      ? setShowEndSubscriptionModal(true)
                      : createOrUpdateResearchItem()
                  }
                  loading={loading}
                >
                  {editingItem ? 'Update Research' : 'Save Research'}
                </Button>
              )}
            </div>
          </div>
        </Col>
      </Row>
      <SubscriptionExpiredModal
        setShowEndSubscriptionModal={setShowEndSubscriptionModal}
        showEndSubscriptionModal={showEndSubscriptionModal}
        users={userDetails?.userInfo?.id}
      />
    </>
  );
};
export default BegginingResearchItem;
