import App from './App';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

import './index.css';

import './index.css';
import { Amplify } from 'aws-amplify';
import outputs from './amplify_outputs.json';
import { store } from './store/store';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

const [redirectSignIn] = outputs.auth.oauth.redirect_sign_in_uri;

const [redirectSignOut] = outputs.auth.oauth.redirect_sign_out_uri;

Amplify.configure(outputs);

const existingConfig = Amplify.getConfig();

console.log('existingConfig', existingConfig);

Amplify.configure({
  ...existingConfig,
  auth: {
    ...outputs.auth,
    oauth: {
      ...outputs.auth.oauth,
      redirect_sign_in_uri: [redirectSignIn],
      redirect_sign_out_uri: [redirectSignOut],
    },
  },
  API: {
    ...existingConfig.API,
    REST: { ...existingConfig?.API?.REST, ...outputs.custom.API },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
