import { Typography, Skeleton, Empty } from 'antd';
import { WinrateImages } from '../../../assets/Images';
import { AINews, Company } from './models';
import { NewsCard } from './CompanyNews';
import { NotesSection } from '../NotesSection';

const { Paragraph } = Typography;

const CrmCompanyLink = ({
  crmCompany,
  crmId,
  crmProvider,
}: {
  crmCompany: any;
  crmId: string;
  crmProvider?: string;
}) => {
  if (crmProvider === 'hubspot') {
    return (
      <>
        &middot;
        <a
          href={`https://app.hubspot.com/contacts/${crmId}/companies/${crmCompany['hs_object_id']}`}
          target="_blank"
          rel="noreferrer"
          style={{
            display: 'flex',
            gap: '4px',
            alignItems: 'center',
            color: '#0072b1',
          }}
        >
          <img
            src={WinrateImages.Icons.hubspotIcon}
            alt="Hubspot logo"
            width="18px"
          />
          Hubspot
        </a>
      </>
    );
  } else if (crmProvider === 'salesforce') {
    //"crmId": "https://winrate2-dev-ed.develop.my.salesforce.com",
    // get "winrate2-dev-ed" from "crmId"
    let salesforceInstance = crmId.split('://')[1];
    salesforceInstance = salesforceInstance.split('.')[0];

    return (
      <>
        &middot;
        <a
          href={`https://${salesforceInstance}.develop.lightning.force.com/lightning/r/Account/${crmCompany['id']}/view`}
          target="_blank"
          rel="noreferrer"
          style={{
            display: 'flex',
            gap: '4px',
            alignItems: 'center',
            color: '#0072b1',
          }}
        >
          <img
            src={WinrateImages.Auth.salesForce}
            alt="Salesforce logo"
            width="18px"
          />
          Salesforce
        </a>
      </>
    );
  }

  return null;
};

export const AboutSection = ({
  loading,
  title,
  company,
  about,
  news,
  crmCompany,
  crmId,
  crmProvider,
}: {
  loading: boolean;
  title?: string;
  company: Company;
  about: any;
  news: AINews[];
  crmCompany?: any;
  crmId?: string;
  crmProvider?: string;
}) => {
  const isEmptyData =
    !loading && !company && !about && (!news || news.length === 0);

  return (
    <div className="note-about">
      <NotesSection
        title={
          title
            ? `${title} ${company?.profile?.name || company?.profile?.domain || ''}`
            : null
        }
      >
        {isEmptyData && (
          <Empty style={{ border: '1px solid #ddd', padding: '15px' }} />
        )}
        {loading && !company && (
          <div
            style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}
          >
            <Skeleton.Input active={true} size={'default'} block={true} />
            <Skeleton active={true} />
            <div style={{ display: 'flex', gap: '10px' }}>
              <Skeleton.Node
                active={true}
                style={{ height: '100px', width: '150px' }}
              />
              <Skeleton.Node
                active={true}
                style={{ height: '100px', width: '150px' }}
              />
            </div>
          </div>
        )}
        {company && (
          <div className="note-about-stats">
            <div className="align-flex">
              <img
                src={`https://logo.clearbit.com/${company?.profile?.domain}`}
                width="18"
              />
              <div>
                <a
                  style={{ color: 'inherit' }}
                  target="_blank"
                  rel="noopener noreferrer"
                  href={company?.profile?.website}
                >
                  {company?.profile?.domain}
                </a>
              </div>
            </div>
            {company?.profile?.founded_year && (
              <>
                &middot;
                <div>Founded: {company?.profile?.founded_year}</div>
              </>
            )}
            {company?.profile?.size && (
              <>
                &middot;
                <div>
                  # of Employees:{' '}
                  <span>
                    {company?.profile?.size[0]}-{company?.profile?.size[1]}
                  </span>
                </div>
              </>
            )}
            &middot;
            <a
              href={company?.profile?.linkedin_url}
              target="_blank"
              rel="noreferrer"
              style={{
                display: 'flex',
                gap: '4px',
                alignItems: 'center',
                color: '#0072b1',
              }}
            >
              <img
                src={`https://logo.clearbit.com/linkedin.com`}
                alt="Linkedin Logo"
                width="18"
              />
              LinkedIn
            </a>
            {crmCompany && crmId && (
              <CrmCompanyLink
                crmCompany={crmCompany}
                crmId={crmId}
                crmProvider={crmProvider}
              />
            )}
          </div>
        )}

        {company && (
          <div className="note-about-description">
            <Paragraph
              className="company-description"
              ellipsis={{
                rows: 3,
                expandable: true,
                symbol: 'more',
              }}
              title={company?.profile?.description}
            >
              {company?.profile?.description}
            </Paragraph>
            {loading && !about?.description && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '20px',
                }}
              >
                <Skeleton.Input active={true} size={'default'} block={true} />
                <Skeleton.Input active={true} size={'default'} block={true} />
                <div style={{ display: 'flex', gap: '10px' }}>
                  <Skeleton.Node
                    active={true}
                    style={{ height: '100px', width: '150px' }}
                  />
                  <Skeleton.Node
                    active={true}
                    style={{ height: '100px', width: '150px' }}
                  />
                </div>
              </div>
            )}
            <ul className="to-summarize">
              {about?.description &&
                about?.description?.toLowerCase() !== 'none' && (
                  <li>
                    <strong>What do they do:</strong>
                    <span> {about?.description}</span>
                  </li>
                )}
              {about?.revenueModel &&
                about?.revenueModel?.toLowerCase() !== 'none' && (
                  <li>
                    <strong>How do they make money:</strong>
                    <span> {about?.revenueModel}</span>
                  </li>
                )}
              {about?.totalFundraising &&
                about?.totalFundraising?.toLowerCase() !== 'none' && (
                  <li>
                    <strong>Fundraising:</strong>
                    <span> {about?.totalFundraising}</span>
                  </li>
                )}
              {about?.notableInvestors &&
                about?.notableInvestors?.length > 0 && (
                  <li>
                    <strong>Investors:</strong>
                    <span> {about?.notableInvestors?.join(', ')}</span>
                  </li>
                )}
            </ul>
          </div>
        )}
        {news?.length > 0 && (
          <div className="note-about-news">
            <h3>News</h3>
            <div
              style={{
                display: 'flex',
                flexWrap: 'nowrap',
                gap: '10px',
                overflowX: 'auto',
                padding: '10px 2px',
                scrollbarWidth: 'thin',
                scrollbarColor: '#DDD rgba(0,0,0,.02)',
              }}
            >
              {news.map((newss) => (
                <NewsCard key={newss.url} news={newss} />
              ))}
            </div>
          </div>
        )}
      </NotesSection>
    </div>
  );
};
