import { Button, Grid, Modal, Row, Typography } from 'antd';
import { WinrateImages } from '../assets/Images';
import { connectCalendar, connectMS } from '../utils/IntegrationHelper';

const ConnectModal = ({
  connectModal,
  setConnectModal,
  users,
}: {
  connectModal: boolean;
  setConnectModal: (open: boolean) => void;
  users?: any;
}) => {
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  return (
    <Modal
      open={connectModal}
      onCancel={() => setConnectModal(false)}
      footer={null}
      className="calendar-sync-modal"
      width={600}
    >
      <Row justify="center">
        <img src={WinrateImages.EventIcons.calendarSyncIcon} />
      </Row>
      <Row justify="center" className="mt-20">
        <Typography.Title level={3} className="title-text fw-500">
          Calendar <span className="sync-text">Sync</span> Needed
        </Typography.Title>
      </Row>
      <Row justify="center">
        <Typography.Text>
          {' '}
          To continue syncing your calendar and receiving meeting
        </Typography.Text>
      </Row>
      <Row justify="center">
        <Typography.Text>
          {' '}
          prep notes, please reconnect your calendar.
        </Typography.Text>
      </Row>
      <Row justify="center" className="mt-20">
        <Button
          className="connect-cta"
          onClick={() => connectCalendar(users as string, 'event_list')}
          icon={<img src={WinrateImages.Auth.google_cal} alt="calendar" />}
        >
          Connect Google Calendar
        </Button>
        <Button
          className={screens.md ? 'connect-cta ml-20' : 'connect-cta mt-10'}
          icon={<img src={WinrateImages.Auth.outlook} alt="calendar" />}
          onClick={() => connectMS(users as string, 'event_list')}
        >
          Connect Outlook Calendar
        </Button>
      </Row>
    </Modal>
  );
};

export default ConnectModal;
