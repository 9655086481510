import { getUrl } from 'aws-amplify/storage';
import { debounce } from 'lodash';
import dayjs from 'dayjs';
import { doGet } from '../sdk/Events';
import { ssoType } from './Utils';

export const fetchS3ImageURL = async (path: string): Promise<string> => {
  let url = '';
  const linkToStorageFile = await getUrl({
    path,
  });
  url =
    linkToStorageFile.url && linkToStorageFile.url?.href
      ? linkToStorageFile.url?.href
      : '';
  return url;
};

export const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  const userTimezoneOffset = date.getTimezoneOffset() * 60000;
  const relativeDate = new Date(date.getTime() + userTimezoneOffset);
  const today = new Date();

  // Check if the input date is today's date
  const isToday = relativeDate.toDateString() === today.toDateString();

  // If it's today, return "Today"
  if (isToday) {
    return 'Today';
  }

  // Otherwise, format the date as "Day, Month Date" (e.g., Wed, Sep 25)
  const formattedDate = new Intl.DateTimeFormat('en-US', {
    weekday: 'short', // e.g., "Wed"
    month: 'short', // e.g., "Sep"
    day: 'numeric', // e.g., "25"
  }).format(relativeDate);

  return formattedDate;
};

export const isToday = (dateString: string) => {
  if (!dateString) return false;
  const date = new Date(dateString);
  const userTimezoneOffset = date.getTimezoneOffset() * 60000;
  const relativeDate = new Date(date.getTime() + userTimezoneOffset);
  const today = new Date();

  // Compare the date with today's date
  return relativeDate.toDateString() === today.toDateString();
};

export const formatTimeToLocal = (dateString: string) => {
  if (!dateString) return '';
  const date = new Date(dateString);

  let hours = date.getHours();
  const minutes = date.getMinutes();
  const isPM = hours >= 12;

  hours = hours % 12 || 12;
  const formattedHours = hours.toString().padStart(2, '0');

  const formattedMinutes = minutes.toString().padStart(2, '0');

  const period = isPM ? 'PM' : 'AM';

  return `${formattedHours}:${formattedMinutes} ${period}`;
};

export function debounceFn(fn: any, delay: any) {
  const debouncedFn = debounce(fn, delay);
  return debouncedFn;
}

const formatTime = (date: string | Date): string => {
  const now = dayjs();
  const inputDate = dayjs(date);
  const diffInMinutes = now.diff(inputDate, 'minute');
  const diffInDays = now.diff(inputDate, 'day');

  if (diffInDays < 1) {
    if (diffInMinutes < 60) {
      return `${diffInMinutes} minute${diffInMinutes === 1 ? '' : 's'} ago`;
    } else {
      const diffInHours = Math.floor(diffInMinutes / 60);
      return `${diffInHours} hour${diffInHours === 1 ? '' : 's'} ago`;
    }
  } else {
    return inputDate.format('MMM DD');
  }
};

export default formatTime;

export const fetchProvider = async (userId: string) => {
  try {
    const apiPath = `setting/integration/${userId}`;
    const response = await doGet(apiPath);

    const providersToCheck = [ssoType.microsoft, ssoType.google];
    const connected = response?.data
      .filter(
        (item: any) =>
          providersToCheck.includes(item.provider) &&
          item.status === 'connected'
      )
      .map((item: any) => item.provider);

    return connected; // Return the connected providers
  } catch (err) {
    console.error('Error fetching providers:', err);
    throw err; // Re-throw the error so it can be handled where the function is used
  }
};

export const fetchConnectedIntegration = async (userId: string) => {
  try {
    const apiPath = `setting/integration/${userId}`;
    const response = await doGet(apiPath);

    const providersToCheck = [ssoType.microsoft, ssoType.google];
    const providersStatus = response?.data.reduce(
      (acc: Record<string, string>, item: any) => {
        if (providersToCheck.includes(item.provider)) {
          acc[item.provider] = item.status;
        }
        return acc;
      },
      {}
    );

    return providersStatus;
  } catch (err) {
    console.error('Error fetching providers:', err);
    throw err;
  }
};
