import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import './ChatMD.less';

export const ChatMD = ({ children }: { children: any }) => {
  return (
    <Markdown className="chat-md" remarkPlugins={[remarkGfm]}>
      {children}
    </Markdown>
  );
};
