import { useEffect, useState } from 'react';
import { Empty, Skeleton, Typography, Avatar, Tooltip, Button } from 'antd';
import { ChatMD } from '../Chat/ChatMD';

// internal imports
import { WinrateImages } from '../../../assets/Images';
import { NotesSection } from '../NotesSection';
import { Transcript } from '../Attendees/models';

const { Text, Link } = Typography;

const RECORDERS = {
  fathom:
    'https://static-landing.fathom.video/37b3f629ce2ae2134fb24163cec5fb59a868a667/img/favicon.svg',
  zoom: <img width="24px" src={WinrateImages.Icons.zoomIcon} alt="zoom logo" />,
  readai:
    'https://cdn.prod.website-files.com/614e5e239ea0f25fe5b6a797/615f7213c6091b296dd6ebd0_ReadLogomark_32x32.png',
  gongio:
    'https://www.gong.io/wp-content/themes/gong/images/favicon/favicon.svg',
  circlebackai: '',
  chorusai: '',
  fireflies: 'https://fireflies.ai/apple-touch-icon.png',
};

const getRecorderLogo = (recorder: string) => {
  const lowerCaseRecorder = recorder.toLowerCase().replaceAll('.', '');
  switch (lowerCaseRecorder) {
    case 'fathom':
      return RECORDERS.fathom;
    case 'zoom':
      return RECORDERS.zoom;
    case 'readai':
      return RECORDERS.readai;
    case 'gongio':
    case 'gong':
      return RECORDERS.gongio;
    case 'circlebackai':
    case 'circleback':
      return RECORDERS.circlebackai;
    case 'chorusai':
    case 'chorus':
      return RECORDERS.chorusai;
    case 'fireflies':
    case 'firefliesai':
      return RECORDERS.fireflies;
    default:
      return '';
  }
};

export const CatchUp = ({
  loading,
  title,
  summary,
  emailNotes,
  crmNotes,
  crmProvider,
  transcriptNotes,
}: {
  loading: boolean;
  title?: string;
  summary: string;
  emailNotes: string;
  crmNotes: any;
  crmProvider: string;
  transcriptNotes: Transcript[];
}) => {
  const [allNotes, setAllNotes] = useState('');
  const [transcripts, setTranscripts] = useState<Transcript[]>([]);
  const [showAll, setShowAll] = useState(false);

  useEffect(() => {
    if (crmNotes) {
      setAllNotes(
        Object.keys(crmNotes)
          .map((key: string) => crmNotes?.[key]?.summary)
          .join('\n')
      );
    }
  }, [crmNotes]);

  useEffect(() => {
    if (transcriptNotes) {
      // sort by date
      const sortedTranscripts = transcriptNotes.sort((a, b) => {
        return new Date(b.date).getTime() - new Date(a.date).getTime();
      });

      setTranscripts(sortedTranscripts);
    }
  }, [transcriptNotes]);

  return (
    <div className="note-context">
      <NotesSection title={title}>
        {loading && !summary && !emailNotes && <Skeleton active={true} />}
        {!loading && !summary && !emailNotes && !crmProvider && !crmNotes && (
          <Empty style={{ border: '1px solid #ddd', padding: '15px' }} />
        )}
        <div className="note-context-content">
          <div>{summary}</div>
          {emailNotes && (
            <div
              style={{
                display: 'grid',
                gap: '10px',
                alignItems: 'flex-start',
                gridTemplateColumns: 'auto 1fr',
              }}
            >
              <Tooltip title="GMail">
                <img
                  src={WinrateImages.Icons.gmailIcon}
                  alt="GMail logo"
                  width="24px"
                  style={{ padding: '4px 0px' }}
                />
              </Tooltip>
              <ChatMD>{emailNotes}</ChatMD>
            </div>
          )}

          {crmProvider && allNotes && (
            <div
              style={{
                display: 'grid',
                gap: '10px',
                alignItems: 'flex-start',
                gridTemplateColumns: 'auto 1fr',
              }}
            >
              {crmProvider === 'hubspot' && (
                <Tooltip title="Hubspot">
                  <img
                    src={WinrateImages.Icons.hubspotIcon}
                    alt="Hubspot logo"
                    width="24px"
                    style={{ padding: '4px 0px' }}
                  />
                </Tooltip>
              )}
              {crmProvider === 'salesforce' && (
                <Tooltip title="Salesforce">
                  <img
                    src={WinrateImages.Auth.salesForce}
                    alt="Salesforce logo"
                    width="24px"
                    style={{ padding: '4px 0px' }}
                  />
                </Tooltip>
              )}
              <ChatMD>{allNotes}</ChatMD>
            </div>
          )}

          {/* {transcriptNotes && (
            <div
              style={{
                display: 'flex',
                gap: '10px',
                alignItems: 'flex-start',
              }}
            >
              <div className="transcript-notes">
                <ChatMD>
                  {'### Previous Meeting Transcripts\n' + transcriptNotes}
                </ChatMD>
              </div>
            </div>
          )} */}
          {transcripts && transcripts.length > 0 && (
            <div
              style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}
            >
              {transcripts
                .slice(0, showAll ? transcripts.length : 1)
                .map((transcript, index) => (
                  <div
                    key={`transcript-${index}`}
                    style={{
                      display: 'grid',
                      gap: '10px',
                      alignItems: 'flex-start',
                      gridTemplateColumns: 'auto 1fr',
                    }}
                  >
                    <Tooltip title={transcript.recorder}>
                      <Avatar
                        shape="square"
                        size={24}
                        src={getRecorderLogo(transcript.recorder)}
                      >
                        {transcript.recorder?.[0]}
                      </Avatar>
                    </Tooltip>
                    <div
                      key={`transcript-${index}`}
                      className="transcript-notes"
                      style={{ fontSize: '16px' }}
                    >
                      <div>
                        <strong>{transcript.title}</strong>
                      </div>
                      <div>
                        <Text type="secondary">{transcript.date}</Text>
                      </div>
                      <ChatMD>
                        {transcript.summary +
                          '\n' +
                          transcript.actionItems
                            .map((item) => `* ${item}`)
                            .join('\n')}
                      </ChatMD>
                      <div style={{ marginTop: '10px' }}>
                        <Link strong href={transcript.link} target="_blank">
                          Full Transcript
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
              {transcripts.length > 1 && (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Button
                    onClick={() => setShowAll(!showAll)}
                    color="default"
                    variant="filled"
                  >
                    <Text strong type="secondary">
                      {showAll ? (
                        'Show Less'
                      ) : (
                        <>{transcripts.length - 1} More</>
                      )}
                    </Text>
                  </Button>
                </div>
              )}
            </div>
          )}
        </div>
      </NotesSection>
    </div>
  );
};
