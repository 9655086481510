import { Checkbox, Input, message, Modal, Row, Typography } from 'antd';
import PrimaryButton from '../../../common/PrimaryButton';
import SecondaryButton from '../../../common/SecondaryButton';
import React, { useState } from 'react';
import SuccessModal from './SuccessModal';
import { doGet, doPost } from '../../../sdk/Events';
import dayjs from 'dayjs';
import { setPaymentStatus } from '../../../store/authSlice';
import { useDispatch } from 'react-redux';

interface CancelSubscriptionFeedbackProps {
  setSubscriptionFeedbackModal: (open: boolean) => void;
  subscriptionFeedbackModal: boolean;
  userDetails: any;
  setUsersDetails?: (details: any) => void | undefined;
}
const CancelSubscriptionFeedback: React.FC<CancelSubscriptionFeedbackProps> = ({
  subscriptionFeedbackModal,
  setSubscriptionFeedbackModal,
  userDetails,
  setUsersDetails,
}) => {
  const { TextArea } = Input;

  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [feedbackValues, setFeedbackValues] = useState<
    { type: string; text: string }[]
  >([]);
  const [textAreaValues, setTextAreaValues] = useState<{
    [key: string]: string;
  }>({});
  const [selectedCheckboxes, setSelectedCheckboxes] = useState<string[]>([]);

  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const handleCheckboxChange = (checkedValues: string[]) => {
    setSelectedCheckboxes(checkedValues);
    const updatedFeedback = checkedValues.map((value) => ({
      type: value,
      text: textAreaValues[value] || '',
    }));

    setFeedbackValues(updatedFeedback);
  };

  const handleTextAreaChange = (key: string, text: string) => {
    setTextAreaValues((prev) => ({ ...prev, [key]: text }));
    setFeedbackValues((prev) =>
      prev.map((item) => (item.type === key ? { ...item, text } : item))
    );
  };

  const getUserPaymentStatus = async () => {
    try {
      const response = await doGet('user');
      setUsersDetails?.(response.data);
      dispatch(setPaymentStatus(response?.data?.subscription_status));
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const cancelSubscription = async () => {
    if (feedbackValues?.length === 0) {
      message.error('Please provide feedback to cancel your subscription');
      return;
    }

    const emptyTextbox = feedbackValues.some(
      (feedback) => feedback.text.trim() === ''
    );

    if (emptyTextbox) {
      message.error(
        'Please provide additional details for selected feedback items.'
      );
      return;
    }

    setLoading(true);
    try {
      const inputData = {
        user_id: userDetails?.id,
        email: userDetails?.email,
        reason: {
          feedback: feedbackValues,
        },
      };
      await doPost('cancel-subscription', inputData);
      await getUserPaymentStatus();
      setSuccessModalOpen(true);
      setSubscriptionFeedbackModal(false);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error cancelling subscription:', error);
    }
  };
  return (
    <>
      <Modal
        open={subscriptionFeedbackModal}
        onCancel={() => {
          setFeedbackValues([]);
          setTextAreaValues({});
          setSelectedCheckboxes([]);
          setSubscriptionFeedbackModal(false);
        }}
        closable={false}
        footer={
          <>
            <SecondaryButton
              text="Cancel"
              onClick={() => {
                setSubscriptionFeedbackModal(false);
                setFeedbackValues([]);
                setTextAreaValues({});
                setSelectedCheckboxes([]);
              }}
            />
            <PrimaryButton
              text="Cancel Subscription"
              onClick={() => {
                cancelSubscription();
              }}
              disabled={loading || feedbackValues?.length === 0}
              loading={loading}
              className="disabled-btn"
            />
          </>
        }
      >
        <Row>
          <Typography.Title level={4} className="fw-500">
            We&apos;d love to hear your feedback!
          </Typography.Title>
        </Row>
        <Row>
          <Typography.Text className="label-text mt-10">
            What made you decide to cancel your subscription?
          </Typography.Text>
        </Row>

        <Checkbox.Group
          className="feedback-checkbox"
          onChange={(values) => handleCheckboxChange(values as string[])}
          value={selectedCheckboxes}
        >
          <Checkbox className="mt-10" value="not_finding_the_features">
            Not finding the features I need{' '}
          </Checkbox>
          {feedbackValues.some(
            (item) => item.type === 'not_finding_the_features'
          ) && (
            <TextArea
              placeholder="Please share more details..."
              className="textarea-style"
              autoSize={{ minRows: 3, maxRows: 5 }}
              value={textAreaValues['not_finding_the_features'] || ''}
              onChange={(e) =>
                handleTextAreaChange('not_finding_the_features', e.target.value)
              }
            />
          )}
          <Checkbox className="mt-10" value="privacy_or_security">
            Concerns about privacy or security
          </Checkbox>
          {feedbackValues.some(
            (item) => item.type === 'privacy_or_security'
          ) && (
            <TextArea
              placeholder="Please share more details..."
              className="textarea-style"
              autoSize={{ minRows: 3, maxRows: 5 }}
              value={textAreaValues['privacy_or_security'] || ''}
              onChange={(e) =>
                handleTextAreaChange('privacy_or_security', e.target.value)
              }
            />
          )}
          <Checkbox className="mt-10" value="wrong_information">
            Wrong information
          </Checkbox>
          {feedbackValues.some((item) => item.type === 'wrong_information') && (
            <TextArea
              placeholder="Please share more details..."
              className="textarea-style"
              autoSize={{ minRows: 3, maxRows: 5 }}
              value={textAreaValues['wrong_information'] || ''}
              onChange={(e) =>
                handleTextAreaChange('wrong_information', e.target.value)
              }
            />
          )}
          <Checkbox className="mt-10" value="too_expensive">
            Too expensive
          </Checkbox>
          {feedbackValues.some((item) => item.type === 'too_expensive') && (
            <TextArea
              placeholder="Please share more details..."
              className="textarea-style"
              autoSize={{ minRows: 3, maxRows: 5 }}
              value={textAreaValues['too_expensive'] || ''}
              onChange={(e) =>
                handleTextAreaChange('too_expensive', e.target.value)
              }
            />
          )}
          <Checkbox className="mt-10" value="notifications_or_emails">
            Too many notifications or emails
          </Checkbox>
          {feedbackValues.some(
            (item) => item.type === 'notifications_or_emails'
          ) && (
            <TextArea
              placeholder="Please share more details..."
              className="textarea-style"
              autoSize={{ minRows: 3, maxRows: 5 }}
              value={textAreaValues['notifications_or_emails'] || ''}
              onChange={(e) =>
                handleTextAreaChange('notifications_or_emails', e.target.value)
              }
            />
          )}
          <Checkbox className="mt-10" value="Other">
            Other
          </Checkbox>
          {feedbackValues.some((item) => item.type === 'Other') && (
            <TextArea
              placeholder="Please share more details..."
              className="textarea-style"
              autoSize={{ minRows: 3, maxRows: 5 }}
              value={textAreaValues['Other'] || ''}
              onChange={(e) => handleTextAreaChange('Other', e.target.value)}
            />
          )}
        </Checkbox.Group>
        <Row className="mt-20">
          <Typography.Text>
            Your plan will not be auto renewed but you can access all premium
            features until{' '}
            {dayjs(userDetails?.next_renewal_date).format('MMM DD, YYYY')}. No
            refunds will be made.
          </Typography.Text>
        </Row>
      </Modal>
      <SuccessModal
        setSuccessModalOpen={setSuccessModalOpen}
        successModalOpen={successModalOpen}
        userDetails={userDetails}
      />
    </>
  );
};

export default CancelSubscriptionFeedback;
