import {
  Avatar,
  Button,
  Dropdown,
  Flex,
  Grid,
  message,
  Row,
  Typography,
} from 'antd';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { WinrateImages } from '../assets/Images';
import {
  UserOutlined,
  SettingOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from '@ant-design/icons';
import '../App.less';
import type { MenuProps } from 'antd';
import { fetchAuthSession, signOut } from 'aws-amplify/auth';
import { Link, useNavigate } from 'react-router-dom';
import { doGet } from '../sdk/Events';
import { useDispatch } from 'react-redux';
import {
  setIsCalenderVisible,
  setPaymentStatus,
  setTrialDays,
} from '../store/authSlice';
import SubscriptionExpiredModal from '../MyAccount/components/Subscription/SubscriptionExpiredModal';
import downIcon from '../assets/icons/down-icon.svg';
import NavigationModal from './NavigationModal';
import { getUrl } from 'aws-amplify/storage';

interface HeaderProps {
  setCollapsed?: Dispatch<SetStateAction<boolean>>;
  collapsed?: boolean;
}

type FreeTrial = {
  daysLeft: string;
  trialEndDate: string;
};

type UsersArray = {
  subscription_status: string;
};

// const NotificationMenu = ({
//   bellIcon,
//   allNotifications,
//   setIsHovered,
//   isHovered,
//   markasRead,
//   readAllNotification,
//   unReadNotifications,
//   navigate,
//   loading,
//   markasUnRead,
//   readLoader,
//   usersPaymentStatus,
//   setShowEndSubscriptionModal,
// }: {
//   bellIcon: any;
//   allNotifications: any;
//   isHovered: number | null;
//   setIsHovered: React.Dispatch<React.SetStateAction<number | null>>;
//   markasRead: (item: any) => Promise<void>;
//   readAllNotification: () => void;
//   unReadNotifications: string;
//   navigate: any;
//   loading: boolean;
//   markasUnRead: (item: any) => Promise<void>;
//   readLoader: boolean;
//   usersPaymentStatus: any;
//   setShowEndSubscriptionModal: (open: boolean) => void;
// }) => {
//   return (
//     <Popover
//       overlayClassName="responsive-popover"
//       // overlayClassName="notification-popover"
//       placement="bottomRight"
//       title={
//         <Row justify="space-between" align="middle">
//           <Typography.Title level={5} className="fw-500 mb-0">
//             Notifications
//           </Typography.Title>
//           <div>
//             {readLoader && <LoadingOutlined className="fs-20" />}
//             {allNotifications.notifications?.length > 0 && (
//               <Button
//                 type="text"
//                 className="branding-color p-0 ml-10"
//                 onClick={readAllNotification}
//               >
//                 Mark all as read
//               </Button>
//             )}
//           </div>
//         </Row>
//       }
//       content={
//         <>
//           {loading ? (
//             <Skeleton paragraph={{ rows: 4 }} />
//           ) : (
//             <List
//               className="notification-list"
//               itemLayout="horizontal"
//               dataSource={allNotifications.notifications}
//               renderItem={(item: any, index) => (
//                 <List.Item
//                   key={index}
//                   onMouseEnter={() => setIsHovered(index)}
//                   onMouseLeave={() => setIsHovered(null)}
//                   onClick={() => {
//                     if (
//                       usersPaymentStatus?.subscription_status === 'expired' ||
//                       usersPaymentStatus?.subscription_status === 'ended'
//                     ) {
//                       setShowEndSubscriptionModal(true);
//                     } else if (item?.type === 'prep_note') {
//                       navigate(`/notes/${item?.body?.summaryId}`);
//                       markasRead(item);
//                     }
//                   }}
//                   className="cursor-pointer"
//                 >
//                   <List.Item.Meta
//                     avatar={
//                       <Row align="middle">
//                         {!item?.is_read && <Badge color="#0b8751" />}
//                         <img
//                           src={WinrateImages.Icons.prepnoteNotificationIcon}
//                           className="ml-10 mr-10"
//                         />
//                       </Row>
//                     }
//                     title={
//                       <Row justify="space-between" className="mb-0">
//                         <Col md={22} sm={24} xs={24} lg={22} xl={22}>
//                           <Typography.Text className="fw-500">
//                             {item.body?.message}
//                           </Typography.Text>
//                         </Col>
//                         <Col md={2} sm={24} xs={24} lg={4} xl={2}>
//                           {isHovered === index && !item?.is_read && (
//                             <Tooltip title="Mark as read">
//                               <Button
//                                 onClick={(e) => {
//                                   e.stopPropagation();
//                                   markasRead(item);
//                                 }}
//                                 type="text"
//                                 className="p-0 mark-read-icon"
//                                 icon={<CheckSquareOutlined />}
//                               ></Button>
//                             </Tooltip>
//                           )}
//                           {isHovered === index && item?.is_read && (
//                             <Tooltip title="Mark as unread">
//                               <Button
//                                 onClick={(e) => {
//                                   e.stopPropagation();
//                                   markasUnRead(item);
//                                 }}
//                                 type="text"
//                                 className="p-0 mark-read-icon"
//                                 icon={
//                                   <img src={WinrateImages.Icons.unreadIcon} />
//                                 }
//                               ></Button>
//                             </Tooltip>
//                           )}
//                         </Col>
//                       </Row>
//                     }
//                     description={formatTime(item?.created_at)}
//                   />
//                 </List.Item>
//               )}
//             />
//           )}
//           {allNotifications.notifications?.length > 0 && (
//             <Row>
//               <Divider className="notification-divider" />
//             </Row>
//           )}
//         </>
//       }
//     >
//       <div className="bellicon">
//         <div className="badge-wrapper">
//           <Button type="text" className="bell-icon p-0">
//             <img src={bellIcon} alt="" />
//           </Button>
//           <Badge
//             className="badge-position"
//             count={unReadNotifications?.length}
//             size="small"
//             style={{ right: '-5px' }}
//           ></Badge>
//         </div>
//       </div>
//     </Popover>
//   );
// };

const HeaderComponent: React.FC<HeaderProps> = ({
  setCollapsed,
  collapsed,
}: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loginLogo } = WinrateImages.Auth;
  const [navigationModal, setNavigationModal] = useState(false);
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const handleSignOut = () => {
    signOut({
      global: false,
    })
      .then(async () => {
        localStorage.clear();
        try {
          await new Promise((resolve) => {
            window.postMessage({ action: 'logout' }, '*');
            // Give the extension a short time to process the message
            setTimeout(resolve, 500);
          });
        } catch (error) {
          console.warn('Failed to notify extension of logout:', error);
        }
        navigate('/login');
      })
      .catch((err: any) => {
        message.error(err.message);
      });
  };

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <>
          <Row>
            <Typography.Text className="fw-500">My Account</Typography.Text>
          </Row>
          <Row>
            <Typography.Text className="text-secondary mob-txt">
              Profile, Subscription & Company Info{' '}
            </Typography.Text>
          </Row>
        </>
      ),

      onClick: () => navigate('/my-account/my_info'),
      icon: <img src={WinrateImages.Icons.myAccountIcon} />,
    },
    {
      type: 'divider',
    },
    {
      key: '2',
      label: (
        <>
          <Row>
            <Typography.Text className="fw-500">Contact Us</Typography.Text>
          </Row>
          <Row>
            <Typography.Text className="text-secondary mob-txt">
              Talk to our team
            </Typography.Text>
          </Row>
        </>
      ),
      icon: <img src={WinrateImages.Icons.contactUsIcon} />,
    },
    {
      type: 'divider',
    },
    {
      key: '3',
      label: (
        <>
          <Row>
            <Typography.Text className="fw-500">Help Center</Typography.Text>
          </Row>
          <Row>
            <Typography.Text className="text-secondary mob-txt">
              Find articles to resolve issues
            </Typography.Text>
          </Row>
        </>
      ),
      icon: <img src={WinrateImages.Icons.helpCenterIcon} />,
    },
    {
      type: 'divider',
    },
    {
      key: '4',
      label: (
        <>
          <Row>
            <Typography.Text className="fw-500">Logout</Typography.Text>
          </Row>
        </>
      ),
      onClick: () => handleSignOut(),
      icon: <img src={WinrateImages.Icons.logOutIcon} />,
    },
    {
      type: 'divider',
    },
    {
      key: '5',
      label: <Typography.Text>Terms & Condition</Typography.Text>,
    },
    {
      key: '6',
      label: <Typography.Text>Privacy Policy</Typography.Text>,
    },
  ];
  // const [allNotifications, setAllNotifications] = useState([]);
  // const [isHovered, setIsHovered] = useState<number | null>(null);
  const [usersID, setUsersID] = useState<string | null>(null);
  // const [unReadNotifications, setUnReadNotifications] = useState('');
  // const [loading, setLoading] = useState(true);
  // const [readLoader, setReadLoader] = useState(false);
  const [freeTrialDate, setFreeTrialDate] = useState({} as FreeTrial);
  const [usersPaymentStatus, setUsersPaymentStatus] = useState(
    {} as UsersArray
  );
  const [showEndSubscriptionModal, setShowEndSubscriptionModal] =
    useState(false);

  const [profileImageUrl, setProfileImageUrl] = useState<string | null>(null);

  const fetchUserID = async () => {
    try {
      const response = await fetchAuthSession();
      const { winrate_id: getUserID = '' }: any =
        response?.tokens?.idToken?.payload || {};

      if (getUserID) {
        setUsersID(getUserID);
      }
    } catch (err) {
      console.log(err);
    }
  };

  // const fetchNotifications = async () => {
  //   try {
  //     const apiPath = `notification-history?user_id=${usersID}&page=1&limit=20`;
  //     const response = await doGet(apiPath);
  //     setAllNotifications(response.data.data);
  //     setUnReadNotifications(
  //       response?.data?.data?.notifications?.filter(
  //         (item: any) => item.is_read === false
  //       )
  //     );
  //     setLoading(false);
  //   } catch (err) {
  //     setLoading(false);
  //     console.error(err);
  //   }
  // };

  // const markasRead = async (item: any): Promise<void> => {
  //   setReadLoader(true);
  //   try {
  //     const inputData = {
  //       is_read: true,
  //       user_id: usersID,
  //     };
  //     const pathname = `notifications/${item?.id}`;
  //     await doPatch(pathname, inputData);
  //     await fetchNotifications();
  //     setReadLoader(false);
  //   } catch (err) {
  //     setReadLoader(false);
  //     console.log(err);
  //   }
  // };

  // const markasUnRead = async (item: any): Promise<void> => {
  //   setReadLoader(true);
  //   try {
  //     const inputData = {
  //       is_read: false,
  //       user_id: usersID,
  //     };
  //     const pathname = `notifications/${item?.id}`;
  //     await doPatch(pathname, inputData);
  //     await fetchNotifications();
  //     setReadLoader(false);
  //   } catch (err) {
  //     setReadLoader(false);
  //     console.log(err);
  //   }
  // };

  // const readAllNotification = async (): Promise<void> => {
  //   setReadLoader(true);
  //   try {
  //     const pathname = `notifications/${usersID}`;
  //     await doPut(pathname);
  //     await fetchNotifications();
  //     setReadLoader(false);
  //   } catch (err) {
  //     setReadLoader(false);
  //     console.log(err);
  //   }
  // };

  const fetchFreeTrialDays = async () => {
    try {
      const response = await doGet('user/trial');
      setFreeTrialDate(response?.data);
      dispatch(setTrialDays(response?.data?.daysLeft));
    } catch (err) {
      console.error('Error fetching free trial status: ', err);
    }
  };

  const fetchFreeTrialStatus = async () => {
    try {
      const payment_status = await doGet('user');
      dispatch(setPaymentStatus(payment_status?.data?.subscription_status));

      setUsersPaymentStatus(payment_status?.data);
    } catch (error) {
      console.error('Error fetching free trial status: ', error);
    }
  };

  const fetchUserInfo = async () => {
    //setReadLoader(true);
    const path = `user`;
    try {
      const response = await doGet(path);
      if (response) {
        const { profile } = response.data;

        try {
          const { url } = await getUrl({ path: profile });
          setProfileImageUrl(url?.href);
        } catch (error) {
          console.error('Error fetching image URL:', error);
        }
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      //setReadLoader(false);
    }
  };

  useEffect(() => {
    fetchUserInfo();
  }, []);

  useEffect(() => {
    fetchFreeTrialStatus();
    fetchFreeTrialDays();
  }, [location.pathname]);

  useEffect(() => {
    fetchUserID();
  }, []);

  // useEffect(() => {
  //   if (usersID) {
  //     fetchNotifications();
  //   }
  // }, [usersID]);

  return (
    <Flex justify="space-between" align="middle" style={{ height: '100%' }}>
      <div className="head-left" style={{ marginLeft: collapsed ? '20px' : 0 }}>
        {!screens.lg ? (
          <Button
            type="text"
            icon={<img src={WinrateImages.Icons.menuIcon} />}
            onClick={() => setNavigationModal(true)}
          />
        ) : (
          <>
            {collapsed && (
              <Link to="/" style={{ display: 'inline-flex' }}>
                <img src={loginLogo} alt="" className="login-logo-mobile mob" />
              </Link>
            )}
            <Button
              type="text"
              icon={
                collapsed ? (
                  <>
                    {/* <img src={foldIcon} alt="" /> */}
                    <div className="collapsed_action_btn">
                      <MenuUnfoldOutlined />
                    </div>
                  </>
                ) : (
                  <>
                    {/* <img src={unfoldIcon} alt="" /> */}
                    <div className="collapsed_action_btn">
                      <MenuFoldOutlined />
                    </div>
                  </>
                )
              }
              onClick={() => {
                setCollapsed(!collapsed);
                dispatch(setIsCalenderVisible(collapsed));
              }}
              className="collapse-btn menu"
            />
          </>
        )}

        {!screens.lg && (
          <img src={loginLogo} alt="" className="login-logo-mobile" />
        )}
      </div>
      <Row align="middle">
        {screens.md && usersPaymentStatus?.subscription_status === 'free' && (
          <Button
            className="freetrial-btn"
            onClick={() => navigate('/my-account/subscription')}
          >
            <img
              src={WinrateImages.SubscriptionIcons.hourGlassIcon}
              className="hourglass-height"
            />
            {freeTrialDate?.daysLeft} Days
          </Button>
        )}
        <Button type="text">
          <SettingOutlined
            className="fs-20"
            onClick={() => navigate('/settings')}
          />
        </Button>
        {/* <NotificationMenu
          bellIcon={bellIcon}
          allNotifications={allNotifications}
          setIsHovered={setIsHovered}
          isHovered={isHovered}
          markasRead={markasRead}
          readAllNotification={readAllNotification}
          unReadNotifications={unReadNotifications}
          navigate={navigate}
          loading={loading}
          markasUnRead={markasUnRead}
          readLoader={readLoader}
          usersPaymentStatus={usersPaymentStatus}
          setShowEndSubscriptionModal={setShowEndSubscriptionModal}
        /> */}

        <Dropdown
          menu={{ items }}
          trigger={['click']}
          className="user-dropdown"
        >
          <span>
            {profileImageUrl ? (
              <Avatar
                className="mb-5"
                src={profileImageUrl}
                style={{ boxShadow: '0 0 2px rgba(0, 0, 0, 0.6)' }}
              />
            ) : (
              <Avatar className="mb-5" icon={<UserOutlined />}></Avatar>
            )}
            {screens.lg ? (
              <span className="ml-10 fw-500">
                {localStorage.getItem('given_name')}
              </span>
            ) : (
              <img src={downIcon} style={{ marginLeft: '7px' }} />
            )}
          </span>
        </Dropdown>
      </Row>
      <SubscriptionExpiredModal
        setShowEndSubscriptionModal={setShowEndSubscriptionModal}
        showEndSubscriptionModal={showEndSubscriptionModal}
        users={usersID}
      />
      <NavigationModal
        navigationModal={navigationModal}
        setNavigationModal={setNavigationModal}
      />
    </Flex>
  );
};

export default HeaderComponent;
