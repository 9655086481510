import { useState, useEffect } from 'react';

const useWebSocket = (urlCallback: () => Promise<string>) => {
  const [socket, setSocket] = useState<WebSocket>();
  const [status, setStatus] = useState<string>('disconnected');
  const [connected, setConnected] = useState<boolean>(false);
  const [messages, setMessages] = useState<any>({});
  const maxRetries = 4;

  useEffect(() => {
    let retries = 0;
    let reconnectTimeout: any;

    const connect = async () => {
      console.log('Connecting WebSocket...');
      const url = await urlCallback();
      const ws = new WebSocket(url);

      ws.onopen = () => {
        console.log('WebSocket connected');
        setStatus('connected');
        setConnected(true);
        retries = 0; // Reset retries on successful connection
      };

      ws.onmessage = (event: any) => {
        console.log('Message received:', event.data);
        const data = JSON.parse(event.data);

        if (data.id === 'pong' || data.id === 'ping') {
          console.log('Pong received');
          return;
        }

        // if (event.data.type === 'tool') {
        //   console.log('Tool message received:', data);
        //   return;
        // }

        setMessages((prev: any) => {
          let currentMessage = prev[data.id];

          if (data.type === 'tool') {
            if (!currentMessage.tools) {
              currentMessage.tools = {};
            }
            if (!currentMessage.tools[data.runId]) {
              currentMessage.tools[data.runId] = { ...data };
            } else {
              const toolInput = currentMessage.tools[data.runId];
              currentMessage.tools[data.runId] = { ...toolInput, ...data };
            }
          } else {
            if (!currentMessage) {
              currentMessage = data;
            } else {
              currentMessage.message += data.message;
              currentMessage.status = data.status;
              currentMessage.type = data.type;
              currentMessage.detail = data.detail;
              currentMessage.index = data.index;
            }

            if (data.status === 'success') {
              currentMessage.message = data.data.fullMessage;
            }
          }

          return {
            ...prev,
            [data.id]: currentMessage,
          };
        });
      };

      ws.onclose = (event) => {
        console.warn('WebSocket closed', event);
        if (retries < maxRetries) {
          setStatus('reconnecting');
          const backoffDelay = Math.min(1000 * Math.pow(2, retries), 30000); // Exponential backoff up to 30 seconds
          retries++;
          reconnectTimeout = setTimeout(connect, backoffDelay);
          console.log(`Reconnecting in ${backoffDelay / 1000} seconds...`);
        } else {
          setStatus('disconnected');
          setConnected(false);
          console.error(
            'Max retries reached. No further reconnections will be attempted.'
          );
        }
      };

      ws.onerror = (event) => {
        console.error('WebSocket error:', event);
        ws.close(); // Close the socket to trigger the retry logic
      };

      setSocket(ws);
    };

    connect();

    // Cleanup function to close the WebSocket and clear timeout
    return () => {
      if (socket) socket.close();
      clearTimeout(reconnectTimeout);
    };
  }, [urlCallback]);

  return { socket, messages, setMessages, status, connected };
};

export default useWebSocket;
