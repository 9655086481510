import React, { useEffect, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import MainLayoutRoute from './MainLayoutRoute';
import Users from '../UserManagement/Pages/Users';
import GroupDetailPage from '../UserManagement/components/AccessGroup/GroupDetailPage';
import TeamDetails from '../UserManagement/components/Teams/TeamDetails';
import PublicLayoutRoute from './PublicLayoutRoute';
import Login from '../Auth/Login/Login';
import Signup from '../Auth/Signup/Signup';
import VerifyEmail from '../Auth/Signup/VerifyEmail';
import SetupAccount from '../Auth/Signup/SetupAccount';
import { Hub } from 'aws-amplify/utils';
import ForgotPassword from '../Auth/ForgotPassword/ForgotPassword';
import ResetPassword from '../Auth/ForgotPassword/ResetPassword';
import { MeetingNotes } from '../Notes/MeetingNotes';
import { fetchAuthSession } from 'aws-amplify/auth';
import EventList from '../EventList/EventListing';
import Settings from '../Settings/index';
import { setUserID } from '../store/authSlice';
import { useDispatch } from 'react-redux';
import MyAccount from '../MyAccount/index';
import { doGet, handleSignOut } from '../sdk/Events';
import SlackConnect from '../Auth/Signup/SlackConnect';
import SalesForceConnect from '../Auth/Signup/SalesForceConnect';
import HubspotConnect from '../Auth/Signup/HubspotConnect';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import PrimaryButton from '../common/PrimaryButton';
import { Typography } from 'antd';
import { WinrateImages } from '../assets/Images';

// Component that handles the Hub.listen logic
const AuthListener: React.FC<{
  setRedirectLoader: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ setRedirectLoader }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const unsubscribe = Hub.listen('auth', async ({ payload }) => {
      console.log('hub event', payload.event);
      switch (payload.event) {
        case 'signedIn':
          break;
        case 'tokenRefresh_failure':
          console.log('token refresh failed');
          handleSignOut();
          break;
        case 'customOAuthState':
          if (payload.data === 'signIn') {
            setRedirectLoader(true);
            const response = await fetchAuthSession();
            const { winrate_id: id = '' } =
              response?.tokens?.idToken?.payload || {};
            dispatch(setUserID(id));
            const res = await doGet('user');
            const usersDetails = res?.data;
            if (!usersDetails?.first_name) {
              navigate('/setup-account/0'); // Redirect for setup account flow when user doesn't exist in the database.
            } else if (
              !usersDetails?.is_google_connected &&
              !usersDetails?.is_microsoft_connected
            ) {
              navigate('/setup-account/1'); // Default or fallback route
            } else {
              if (response?.tokens) {
                localStorage.setItem(
                  'token',
                  response?.tokens?.accessToken as any
                );
              }
              localStorage.setItem('given_name', usersDetails?.first_name);
              navigate('/');
            }
            setRedirectLoader(false);
          } else if (payload.data === 'signup') {
            setRedirectLoader(true);
            const res = await doGet('user');
            const usersDetails = res?.data;
            if (!usersDetails?.first_name) {
              navigate('/setup-account/0'); // Redirect for setup account flow when user doesn't exist in the database.
            } else if (
              !usersDetails?.is_google_connected &&
              !usersDetails?.is_microsoft_connected
            ) {
              navigate('/setup-account/1'); // Default or fallback route
            } else {
              const token = await fetchAuthSession();
              if (token?.tokens) {
                localStorage.setItem(
                  'token',
                  token?.tokens?.accessToken as any
                );
              }
              navigate('/');
            }
            setRedirectLoader(false);
          } else {
            navigate('/'); // Default or fallback route
          }

          break;
        case 'signInWithRedirect':
          break;
      }
    });

    return unsubscribe;
  }, [navigate]);

  return null; // This component doesn't render anything
};

const RoutesApp: React.FC = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const page = urlParams.get('source');
  if (page !== null) {
    if (page === 'notification') {
      window.location.href = '/settings';
    }
  }
  const [redirectLoader, setRedirectLoader] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState(false);

  const token = localStorage.getItem('token');
  if (!token && !redirectUrl && location.pathname.includes('/notes')) {
    sessionStorage.setItem('redirectStored', location.pathname);
    setRedirectUrl(true);
  }

  const FallbackComponent: React.FC<FallbackProps> = ({
    error,
    resetErrorBoundary,
  }) => {
    console.log(error, 'error');
    return (
      <div className="error-boundary">
        <img
          src={WinrateImages.Icons.errorPlaceholderImage}
          alt="error-placeholder"
        />
        <Typography.Title
          level={3}
          className="ml-10 secondary-font-family fw-500 mt-20 mb-10"
        >
          <span className="error-message">Oops!</span> Something went wrong.
        </Typography.Title>

        <PrimaryButton text="Try Again" onClick={resetErrorBoundary} />
      </div>
    );
  };

  return (
    <>
      <AuthListener setRedirectLoader={setRedirectLoader} />

      <Routes>
        <Route
          path="/"
          element={
            <MainLayoutRoute
              element={
                <ErrorBoundary FallbackComponent={FallbackComponent}>
                  <EventList />
                </ErrorBoundary>
              }
            ></MainLayoutRoute>
          }
        ></Route>

        <Route
          path="/notes/:id"
          element={
            <MainLayoutRoute
              element={
                <ErrorBoundary FallbackComponent={FallbackComponent}>
                  <MeetingNotes />
                </ErrorBoundary>
              }
            ></MainLayoutRoute>
          }
        ></Route>

        <Route
          path="/user-management"
          element={<MainLayoutRoute element={<Users />}></MainLayoutRoute>}
        ></Route>
        <Route
          path="/accessgroup-detail"
          element={
            <MainLayoutRoute element={<GroupDetailPage />}></MainLayoutRoute>
          }
        ></Route>
        <Route
          path="/team-detail"
          element={
            <MainLayoutRoute element={<TeamDetails />}></MainLayoutRoute>
          }
        ></Route>
        <Route
          path="/settings"
          element={
            <MainLayoutRoute
              element={
                <ErrorBoundary FallbackComponent={FallbackComponent}>
                  <Settings />
                </ErrorBoundary>
              }
            ></MainLayoutRoute>
          }
        ></Route>
        <Route
          path="/my-account/:key"
          element={
            <MainLayoutRoute
              element={
                <ErrorBoundary FallbackComponent={FallbackComponent}>
                  <MyAccount />
                </ErrorBoundary>
              }
            ></MainLayoutRoute>
          }
        ></Route>
        <Route
          path="/login"
          element={<PublicLayoutRoute element={<Login />}></PublicLayoutRoute>}
        ></Route>
        <Route
          path="/signup"
          element={<PublicLayoutRoute element={<Signup />}></PublicLayoutRoute>}
        ></Route>
        <Route
          path="/verify-email"
          element={
            <PublicLayoutRoute element={<VerifyEmail />}></PublicLayoutRoute>
          }
        ></Route>
        <Route
          path="/setup-account/:stepId"
          element={
            <PublicLayoutRoute
              element={
                <SetupAccount
                  redirectLoader={redirectLoader}
                  setRedirectLoader={setRedirectLoader}
                />
              }
            ></PublicLayoutRoute>
          }
        ></Route>
        <Route
          path="/forgot-password"
          element={
            <PublicLayoutRoute element={<ForgotPassword />}></PublicLayoutRoute>
          }
        ></Route>
        <Route
          path="/reset-password?confirmationcode=:code+email=:email"
          element={
            <PublicLayoutRoute element={<ResetPassword />}></PublicLayoutRoute>
          }
        ></Route>
        <Route
          path="/reset-password"
          element={
            <PublicLayoutRoute element={<ResetPassword />}></PublicLayoutRoute>
          }
        ></Route>
        <Route
          path="/slack-connect"
          element={
            <PublicLayoutRoute element={<SlackConnect />}></PublicLayoutRoute>
          }
        ></Route>
        <Route
          path="/salesforce-connect"
          element={
            <PublicLayoutRoute
              element={<SalesForceConnect />}
            ></PublicLayoutRoute>
          }
        ></Route>
        <Route
          path="/hubspot-connect"
          element={
            <PublicLayoutRoute element={<HubspotConnect />}></PublicLayoutRoute>
          }
        ></Route>
      </Routes>
    </>
  );
};

export default RoutesApp;
