import { CheckCircleFilled } from '@ant-design/icons';
import { Grid, Row, Space, Typography } from 'antd';
import PrimaryButton from '../../common/PrimaryButton';
import { WinrateImages } from '../../assets/Images';
import MobileNonAuthHeader from '../../common/MobileNonAuthHeader';
import SecondaryButton from '../../common/SecondaryButton';
import { useNavigate } from 'react-router';
import { connectHubspot } from '../../utils/IntegrationHelper';
import { useEffect, useState } from 'react';
import { fetchUserID } from '../../common/AuthFunction';

function HubspotConnect() {
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const { authBG, loginLogo } = WinrateImages.Auth;
  const navigate = useNavigate();

  const [userID, setUserID] = useState<string>('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchAndSetUserID = async () => {
      setLoading(true);
      const id = await fetchUserID();
      setUserID(id);
      setLoading(false);
    };

    fetchAndSetUserID();
  }, []);

  return (
    <div className="auth-page">
      {!screens.md && <MobileNonAuthHeader />}
      <div className={!screens.md ? 'auth-page inner' : 'auth-page'}>
        {/* Your main content goes here */}
        {!screens.md ? '' : <img src={authBG} alt="" className="auth-bg" />}
        <div className="onboarding-container email-form-container">
          {screens.md && (
            <img src={loginLogo} alt="Winrate Logo" className="logo mb-20" />
          )}
          <div className="connect-screen">
            <Typography.Title
              level={4}
              className={
                !screens.md ? 'connect-buttons fw-500' : 'context-margin fw-500'
              }
            >
              Enhance Your Prep Note with HubSpot
            </Typography.Title>
            <Space
              align="baseline"
              className={!screens.md ? 'mt-10 mb-10' : ''}
            >
              <CheckCircleFilled style={{ color: 'green' }} />
              <Typography.Text>
                Using HubSpot data, your notes will provide context <br /> on
                prospects interests, helping you focus on what <br /> matters
                most during calls
              </Typography.Text>
            </Space>

            <Space align="baseline" className="mt-10">
              <CheckCircleFilled style={{ color: 'green' }} />
              <Typography.Text>
                Integrating HubSpot adds marketing and lead <br /> insights to
                your meeting prep
              </Typography.Text>
            </Space>
            <Row justify="center" className="connect-buttons">
              <PrimaryButton
                text="Connect to Hubspot"
                disabled={loading}
                onClick={() => connectHubspot(userID as string, 'default')}
              />
              <SecondaryButton
                text="Skip, I'll Do it Later"
                className="ml-20 skip-btn"
                onClick={() => navigate('/setup-account/2')}
              />
            </Row>
          </div>
          <div className="mob-bottom footer-margin">
            <Row
              justify={!screens.md ? 'center' : 'space-between'}
              className="connect-footer"
              align="middle"
            >
              <div>
                <Typography.Text className="mr-5">
                  Terms & Conditions
                </Typography.Text>
                <span className="text-secondary">• </span>
                <Typography.Text className="ml-5">
                  Privacy Policy
                </Typography.Text>
              </div>
              <Typography.Text className="signup-text fs-12 mt-10">
                © {new Date().getFullYear()}, Winrate. All Rights Reserved.
              </Typography.Text>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HubspotConnect;
