import { WinrateImages } from '../../assets/Images';
import React, { useState } from 'react';
import { Button, Col, Form, Input, message, Row, Typography, Grid } from 'antd';
import PrimaryButton from '../../common/PrimaryButton';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  autoSignIn,
  confirmSignUp,
  fetchAuthSession,
  resendSignUpCode,
} from 'aws-amplify/auth';
import MobileNonAuthHeader from '../../common/MobileNonAuthHeader';
import { doGet } from '../../sdk/Events';

const VerifyEmail: React.FC = () => {
  const { authBG, loginLogo, emailIcon } = WinrateImages.Auth;
  const Navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [form] = Form.useForm();
  const location = useLocation();
  const email = location?.state?.username;
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  const handleConfirmSignUp = async (e: { code: string }) => {
    try {
      setLoading(true);
      const username = email || localStorage.getItem('User');

      if (username && e.code) {
        await confirmSignUp({ username, confirmationCode: e.code });
        try {
          await autoSignIn();
          const response = await doGet('user');
          if (
            response?.data?.first_name &&
            response?.data?.is_google_connected
          ) {
            const token = await fetchAuthSession();
            if (token?.tokens) {
              localStorage.setItem('token', token?.tokens?.accessToken as any);
            }
            localStorage.setItem('given_name', response?.data?.first_name);
            Navigate('/');
          } else if (
            response?.data?.first_name &&
            !response?.data?.is_google_connected
          ) {
            Navigate('/setup-account/1');
          } else {
            Navigate('/setup-account/0');
          }
        } catch (error: any) {
          if (
            error.message ===
            'The autoSignIn flow has not started, or has been cancelled/completed.'
          ) {
            localStorage.setItem('User', username);
            message.warning(
              'Please sign-in to complete your account setup to continue.'
            );
            Navigate('/login');
          } else {
            message.error(error.message);
          }
        }
        setLoading(false);
      } else {
        message.error('Username or confirmation code is missing.');
      }
    } catch (error: any) {
      console.error('Error during sign-up confirmation:', error);
      setLoading(false);
      if (error.response?.status === 401) {
        message.error(
          'Your account is not confirmed. Please verify your email.'
        );
      } else {
        message.error(error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  //Function to handle resend Code
  const handleResendCode = async () => {
    setLoader(true);
    try {
      const username = email ? email : localStorage.getItem('User');
      await resendSignUpCode({ username: username });
      message.success('A new confirmation code has been sent.');
      setLoader(false);
    } catch (e) {
      const errorMessage = (e as Error).message || 'An unknown error occurred';
      message.error(errorMessage);
      setLoader(false);
    }
  };
  return (
    <div className={`auth-page ${!screens.md ? 'mob-auth-page' : ''}`}>
      {!screens.md && <MobileNonAuthHeader />}

      {screens.md && <img src={authBG} alt="" className="auth-bg" />}
      <div className="onboarding-container email-form-container verify_wrapper">
        <div className="mob-top">
          {screens.md && (
            <img src={loginLogo} alt="Winrate Logo" className="logo mb-20" />
          )}
          <div className="mt-24">
            <img src={emailIcon} alt="" className="logo" />
          </div>
          <Typography.Title level={4} className="mt-24 signup-text">
            Verify Your Email
          </Typography.Title>
          <Typography.Text className="signup-text text-secondary">
            A verification code has been sent to{'  '}
            <span className="text-primary fw-500 ml-5">
              {' '}
              {email ? email : localStorage.getItem('User')}
            </span>
          </Typography.Text>
          <Typography.Text className="signup-text text-secondary">
            Please enter the code to complete your sign-up.
          </Typography.Text>
          <Row className="signup-text mt-24">
            <Col xs={24} md={12}>
              <Form
                name="login"
                layout="vertical"
                className="login-form-fields"
                requiredMark={false}
                form={form}
                onFinish={(e) => handleConfirmSignUp(e)}
              >
                <Form.Item
                  label="Verification code"
                  name="code"
                  rules={[
                    { required: true, message: 'Please input your code!' },
                  ]}
                >
                  <Input placeholder="6-digit verification code" />
                </Form.Item>

                <Form.Item className="mt-20 mb-10">
                  <PrimaryButton
                    text="Verify"
                    className="login-btn"
                    loading={loading}
                  />
                </Form.Item>
                <Form.Item className="signup-text">
                  <Button
                    type="text"
                    className="branding-color"
                    onClick={handleResendCode}
                    loading={loader}
                  >
                    Resend email
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </div>
        <div className="mob-bottom">
          <Row justify="space-between" className="verify-email-footer">
            <div style={{ marginBottom: '10px' }}>
              <Typography.Text className="mr-5">
                Terms & Conditions
              </Typography.Text>
              <span className="text-secondary">• </span>
              <Typography.Text className="ml-5">Privacy Policy</Typography.Text>
            </div>
            <Typography.Text className="signup-text fs-12">
              © {new Date().getFullYear()}, Winrate. All Rights Reserved.
            </Typography.Text>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default VerifyEmail;
