import { Button, Modal, Row, Typography } from 'antd';
import PrimaryButton from '../common/PrimaryButton';
import { useState } from 'react';
import { doDelete } from '../sdk/Events';
import { connectCalendar } from '../utils/IntegrationHelper';

const GoogleConnectModal = ({
  disconnectMSModal,
  setDisConnectMSModal,
  users,
}: {
  disconnectMSModal: boolean;
  setDisConnectMSModal: (open: boolean) => void;
  users?: any;
}) => {
  const [connectLoading, setConnectLoading] = useState(false);

  const handleGoogleConnect = async () => {
    try {
      setConnectLoading(true);
      const path = `setting/microsoft/${users}`;
      await doDelete(path)
        .then(() => {
          connectCalendar(users, 'notification');
        })
        .catch((err) => console.error(err));
      setConnectLoading(false);
    } catch (err) {
      setConnectLoading(false);
      console.error(err);
    }
  };

  return (
    <Modal
      open={disconnectMSModal}
      onCancel={() => setDisConnectMSModal(false)}
      footer={null}
    >
      <Row justify="center">
        <Typography.Title level={4}>
          Update Calendar Integration
        </Typography.Title>
      </Row>
      <Row justify="center" className="mt-20">
        <Typography.Text className="text-center">
          You are currently signed in with Google, but your calendar is
          connected to Microsoft. To use the same account for both, please
          switch your calendar connection to Google
        </Typography.Text>
      </Row>

      <Row justify="center" className="mt-20">
        <PrimaryButton
          text="Switch Calendar"
          onClick={handleGoogleConnect}
          loading={connectLoading}
          className="switch-btn"
        />
      </Row>
      <Row justify="center" className="mt-10">
        <Button
          type="text"
          onClick={() => setDisConnectMSModal(false)}
          className="later-btn"
        >
          I&apos;ll Do it Later
        </Button>
      </Row>
    </Modal>
  );
};

export default GoogleConnectModal;
