import { fetchAuthSession } from 'aws-amplify/auth';

export const fetchUserID = async (): Promise<string> => {
  try {
    const data = await fetchAuthSession();
    const { winrate_id: id = '' }: any = data?.tokens?.idToken?.payload || {};
    return id;
  } catch (error) {
    console.error('Error fetching user ID:', error);
    return '';
  }
};
