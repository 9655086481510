import { message } from 'antd';
const clientId = process.env.REACT_APP_CLIENT_ID;
//connect function for google calendar
export const connectCalendar = async (user_id: string, originPath: string) => {
  try {
    const encodedState = encodeURIComponent(`${user_id}|${originPath}`);
    const redirect_uri = process.env.REACT_APP_REDIRECT_URL;
    window.location.href = `https://accounts.google.com/o/oauth2/v2/auth?state=${encodedState}&client_id=${clientId}&scope=https://www.googleapis.com/auth/calendar.events.owned.readonly https://www.googleapis.com/auth/gmail.readonly&redirect_uri=${redirect_uri}&prompt=consent&response_type=code&access_type=offline`;
  } catch (error: any) {
    message.error(error.message);
  }
};
//connect function for salesforce
export const connectSalesForce = async (
  user_id: string,
  originPath: string
) => {
  const encodedState = encodeURIComponent(`${user_id}|${originPath}`);
  try {
    window.location.href = `https://login.salesforce.com/services/oauth2/authorize?response_type=code&client_id=${process.env.REACT_APP_SALESFORCE_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_SALESFORCE_REDIRECT_URL}&state=${encodedState}`;
  } catch (err: any) {
    message.error(err.message);
  }
};

//connect function for slack
export const connectSlack = async (user_id: string, originPath: string) => {
  const encodedState = encodeURIComponent(`${user_id}|${originPath}`);

  try {
    window.location.href = `https://slack.com/oauth/v2/authorize?scope=chat:write,im:write,users:read,users:read.email,team:read&redirect_uri=${process.env.REACT_APP_SLACK_REDIRECT_URL}&client_id=${process.env.REACT_APP_SLACK_CLIENT_ID}&tracked=1&state=${encodedState}`;
  } catch (err: any) {
    message.error(err.message);
  }
};

//connect function for hubspot
export const connectHubspot = async (user_id: string, originPath: string) => {
  const encodedState = encodeURIComponent(`${user_id}|${originPath}`);

  try {
    const url = `https://app.hubspot.com/oauth-bridge?client_id=${process.env.REACT_APP_HUBSPOT_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_HUBSPOT_REDIRECT_URL}&scope=crm.objects.line_items.read%20sales-email-read%20oauth%20crm.objects.owners.read%20crm.objects.companies.read%20crm.objects.deals.read%20crm.objects.contacts.read%20cms.domains.read&state=${encodedState}`;
    window.location.href = url;
  } catch (err: any) {
    message.error(err.message);
  }
};

//connect function for microsoft calendar
export const connectMS = async (user_id: string, originPath: string) => {
  try {
    const encodedState = encodeURIComponent(`${user_id}|${originPath}`);
    const url = `${process.env.REACT_APP_MS_AUTH_URL}?client_id=${process.env.REACT_APP_MS_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_MS_REDIRECT_URI}&scope=${process.env.REACT_APP_MS_SCOPES}&response_type=code&state=${encodedState}`;
    window.location.href = url;
  } catch (err: any) {
    message.error(err.message);
  }
};
