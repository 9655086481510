import SearchComponent from '../common/SearchComponent';
import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Divider,
  message,
  Popover,
  Row,
  Tooltip,
  Typography,
  DatePicker,
  Select,
  Checkbox,
  Space,
  Skeleton,
  Card,
  Grid,
  Col,
  Modal,
} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import './eventlisting.less';
import { WinrateImages } from '../assets/Images';
import { fetchAuthSession } from 'aws-amplify/auth';
import {
  debounceFn,
  fetchConnectedIntegration,
  fetchProvider,
  formatDate,
  formatTimeToLocal,
  isToday,
} from '../common/CommonMethod';
import dayjs from 'dayjs';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import {
  setCheckedDomain,
  setDateChange,
  setEndDate,
  setMeetingEvents,
  setSelectedDate,
  setSelectedMonth,
  setStartDate,
} from '../store/authSlice';
import { createMeetingNotes, doGet, doPost, doPatch } from '../sdk/Events';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../store/store';
import _ from 'lodash';
import SecondaryButton from '../common/SecondaryButton';
import utc from 'dayjs/plugin/utc';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

dayjs.extend(utc);
dayjs.extend(isSameOrAfter);
import Group from 'src/assets/notification-icons/Group.svg';
import SubscriptionExpiredModal from '../MyAccount/components/Subscription/SubscriptionExpiredModal';
import ReAuthoRisedModal from './ReAuthoRisedModal';
import { setUserDetails } from '../store/authSlice';
import moment from 'moment';
import MSConnectModal from './MSConnectModal';
import GoogleConnectModal from './GoogleConnectModal';
import PrimaryButton from '../common/PrimaryButton';
import { connectCalendar, connectMS } from '../utils/IntegrationHelper';
import ConnectModal from './ConnectModal';
import { ssoType } from '../common/Utils';

interface User {
  email: string;
  id: string;
}
interface Event {
  start_time: string;
  end_time: string;
  summary: string;
  external_domains: [];
  email: string;
  meeting_id: string;
  event_summary_id: string | null;
}
interface EventsResponse {
  meetings: Event[];
}

interface NotificationItem {
  notifications: {
    text?: any;
    email?: any;
    slack?: any;
    in_app?: any;
    snooze?: {
      snooze_until: string | null;
      notification_types: string[];
    };
  };
}

interface SnoozeNotificationType {
  snooze_until: string | null; // Adjust the type of `snooze_until` if it can have a different type
  notification_types: string[]; // Assuming `notification_types` is an array of strings
}

type ProviderStatus = {
  [key: string]: string;
};
const EventList: React.FC = () => {
  const { RangePicker } = DatePicker;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { filterIcon, refreshIcon, summaryGeneratedIcon, prepNoteIcon } =
    WinrateImages.EventIcons;

  const selectedMonth = useSelector(
    (state: RootState) => state.auth.selectedMonth
  );

  const selectedDate = useSelector(
    (state: RootState) => state.auth.selectedDate
  );

  const dateChange = useSelector((state: RootState) => state.auth.dateChange);
  const startDate = useSelector((state: RootState) => state.auth.startDate);
  const endDate = useSelector((state: RootState) => state.auth.endDate);
  const checkedDomain = useSelector(
    (state: RootState) => state.auth.checkedDomain
  );
  const paymentStatus = useSelector(
    (state: RootState) => state.auth.payment_status
  );

  const [users, setUsers] = useState<User | undefined>(undefined);
  const [eventsResponse, setEventResponse] = useState<
    EventsResponse | undefined
  >(undefined);
  const [domainDetails, setDomainDetails] = useState<
    EventsResponse | undefined
  >(undefined);
  const [search, setSearch] = useState<string>('');
  const [selectedDomains, setSelectedDomains] = useState<string[]>(() => {
    const storedDomains = localStorage.getItem('selectedDomains');
    return storedDomains ? JSON.parse(storedDomains) : [];
  });
  const [dateRange, setDateRange] = useState<
    [dayjs.Dayjs | null, dayjs.Dayjs | null]
  >(() => {
    const storedDateRange = localStorage.getItem('dateRange');
    if (storedDateRange) {
      const parsedRange = JSON.parse(storedDateRange);
      return [dayjs(parsedRange[0]), dayjs(parsedRange[1])];
    }
    return [null, null];
  });
  const [selectedFilters, setSelectedFilters] = useState(false);
  const [searchText, setSearchText] = useState<string | null>(null);
  const [filterLoader, setFilterLoader] = useState(false);
  const [eventLoader, setEventLoader] = useState(true);
  const [createLoader, setCreateLoader] = useState('');
  const [domainLoading, setDomainLoading] = useState(false);
  const [filterClick, setFilterClick] = useState(false);
  const [hasTodayEvents, setHasTodayEvents] = useState(false);
  const [snoozeNotificationType, setSnoozeNotificationType] =
    useState<SnoozeNotificationType>({
      snooze_until: null,
      notification_types: [],
    });
  const [notificationData, setNotificationData] = useState<
    NotificationItem[] | null
  >(null);
  const [resumeLoader, setResumeLoader] = useState(false);
  const [showEndSubscriptionModal, setShowEndSubscriptionModal] =
    useState(false);
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const [showReAuthoRisedModal, setShowReAuthoRisedModal] = useState(false);

  const [open, setOpen] = useState(false);

  const hide = () => {
    setOpen(false);
  };

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const [connectedProviders, setConnectedProviders] = useState<string[]>([]);
  const [connectedCalendar, setConnectedCalendar] = useState<ProviderStatus>(
    {}
  );
  const [disconnectGoogleModal, setDisConnectGoogleModal] = useState(false);
  const [disconnectMSModal, setDisConnectMSModal] = useState(false);
  const [openGoogleConnectModal, setOpenGoogleConnectModal] = useState(false);
  const [openMSModal, setOpenMSModal] = useState(false);
  const [connectModal, setConnectModal] = useState(false);

  const handlePrevMonth = () => {
    if (!startDate && !endDate) {
      const prevMonthDate = selectedDate.subtract(1, 'month');
      dispatch(setSelectedMonth(prevMonthDate));
      dispatch(setSelectedDate(prevMonthDate));
    }
  };

  const handleNextMonth = () => {
    if (!startDate && !endDate) {
      const nextMonthDate = selectedDate.add(1, 'month');
      dispatch(setSelectedMonth(nextMonthDate));
      dispatch(setSelectedDate(nextMonthDate));
    }
  };

  const fetchTodayDate = () => {
    dispatch(setDateChange(true));
    dispatch(setSelectedDate(dayjs()));
    dispatch(setSelectedMonth(dayjs()));
  };

  const fetchUsersID = async () => {
    try {
      const response = await fetchAuthSession();
      const { email = '', winrate_id: id = '' }: any =
        response?.tokens?.idToken?.payload || {};
      setUsers({ email, id });
    } catch (error: any) {
      return null;
    }
  };

  const onSearch = async (searchText: string) => {
    if (searchText !== '' && users !== undefined) {
      setSearchText(searchText);
      await fetchCalendarEvents(searchText);
    } else {
      setSearchText(null);
      await fetchCalendarEvents('');
    }
  };

  // Use useCallback to memoize the debounced function
  const debouncedHandleInput = useCallback(debounceFn(onSearch, 1500), [
    selectedDate,
    users,
    startDate,
    endDate,
    connectedProviders,
  ]);

  const handleInputSearch = async (searchText: string) => {
    setSearch(searchText);
    debouncedHandleInput(searchText);
  };

  const groupByDate = (events: Event[]) => {
    return events.reduce((acc: Record<string, Event[]>, event) => {
      const eventDateStr = dayjs(event.start_time).format('YYYY-MM-DD');
      if (!acc[eventDateStr]) {
        acc[eventDateStr] = [];
      }
      acc[eventDateStr].push(event);
      return acc;
    }, {});
  };

  const groupedEvents = groupByDate(eventsResponse?.meetings || []);

  useEffect(() => {
    const today = dayjs().format('YYYY-MM-DD');
    if (groupedEvents[today] && groupedEvents[today].length > 0) {
      setHasTodayEvents(true);
    } else {
      setHasTodayEvents(false);
    }
  }, [groupedEvents]);

  const handleDateFilter = async (value: any) => {
    setDateRange(value);
    if (value) {
      await showExternalDomains(value[0], value[1]);
    }
  };

  const uniqueDomainsSet = new Set<string>();

  const options =
    domainDetails?.meetings?.flatMap(
      (event) =>
        event?.external_domains
          ?.filter((domain: string) => {
            const domainName = domain?.split('@')[1];

            if (
              domainName &&
              domainName !== 'gmail.com' &&
              !uniqueDomainsSet.has(domainName)
            ) {
              uniqueDomainsSet.add(domainName);
              return true; // Keep unique domain name
            }
            return false; // Skip duplicates
          })
          .map((domain: string, index) => ({
            key: index,
            label: domain?.split('@')[1], // Extract domain for label
            value: domain,
          })) || []
    ) || [];

  const clearFilters = () => {
    dispatch(setStartDate(''));
    dispatch(setEndDate(''));
    setSelectedDomains([]);
    localStorage.removeItem('selectedDomains');
    localStorage.removeItem('dateRange');
    setDateRange([null, null]);
    dispatch(setCheckedDomain([]));
  };

  useEffect(() => {
    const storedDomains = localStorage.getItem('selectedDomains');
    if (storedDomains) {
      setSelectedDomains(JSON.parse(storedDomains));
    }
    const storedDateRange = localStorage.getItem('dateRange');
    if (storedDateRange) {
      const parsedRange = JSON.parse(storedDateRange);
      dispatch(setStartDate(parsedRange?.[0]));
      dispatch(setEndDate(parsedRange?.[1]));
    }
  }, []);

  const generateMeetingThenRedirect = async (event: any) => {
    try {
      if (event.event_summary_id) {
        navigate(`/notes/${event.event_summary_id}`);
        return;
      }
      setCreateLoader(event.meeting_id);
      const domains = event.external_domains.map(
        (email: string) => email.split('@')[1]
      );
      const domain = domains?.[0];
      const payload = {
        start_time: event.start_time,
        end_time: event.end_time,
        description: event.summary,
        summary: event.summary,
        attendees: event?.attendees?.map((attendee: any) => attendee.email),
        organizer: event.organizer?.email,
        meeting_id: event.meeting_id,
        domain,
      };
      const response = await createMeetingNotes(payload);
      if (response && response?.data?.summaryId) {
        navigate(`/notes/${response?.data?.summaryId}`);
      }
    } catch (error) {
      console.error(error);
      setCreateLoader('');
    }
  };

  const showResumeNotificationTime = async () => {
    try {
      const pathName = `setting/notification/${users?.id}`;
      const response = await doGet(pathName);
      const getNotificatonData = response?.data?.[0]?.notifications;
      setNotificationData(response?.data);
      setSnoozeNotificationType(getNotificatonData?.snooze);
    } catch (error) {
      console.log(error);
    }
  };

  const handleResumeNotifications = async () => {
    setResumeLoader(true);
    try {
      const notifications = notificationData?.[0]?.notifications || {};

      const updatedNotifications = {
        ...notifications,
        snooze: {
          snooze_until: null,
          notification_types: [],
        },
      };
      const pathName = 'setting';
      const inputData = {
        feature: 'notification',
        user_id: users?.id,
        notificationSettings: updatedNotifications,
      };
      await doPost(pathName, inputData);
      await showResumeNotificationTime();
      message.success('Notifications resumed successfully');
      setResumeLoader(false);
    } catch (err) {
      setResumeLoader(false);
      message.error('Failed to resume notifications');
    }
  };

  useEffect(() => {
    if (users) {
      showResumeNotificationTime();
    }
  }, [users]);

  const checkUsersTimeZone = async () => {
    try {
      const response: any = await doGet('user');
      const userDetailResponse = {
        organization_id: response?.data?.organization_id,
        userInfo: {
          id: response?.data?.id,
          email: response?.data?.email,
          first_name: response?.data?.first_name,
          last_name: response?.data?.last_name,
          phone: response?.data?.phone,
          subscription_status: response?.data?.subscription_status,
        },
      };
      const redirectUrl = sessionStorage.getItem('redirectStored');
      if (redirectUrl) {
        navigate(redirectUrl);
        sessionStorage.removeItem('redirectStored');
      }
      if (userDetailResponse) {
        localStorage.setItem('UserDetails', JSON.stringify(userDetailResponse));
        dispatch(setUserDetails(userDetailResponse));
      }
      localStorage.setItem('given_name', response?.data?.first_name);
      if (response?.data?.timezone !== moment.tz.guess()) {
        const inputData = {
          user: {
            timezone: moment.tz.guess(),
          },
        };
        await doPatch('user', inputData);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    checkUsersTimeZone();
  }, []);

  function Events(): React.ReactNode {
    return Object.keys(groupedEvents).map((item) => {
      return (
        <div key={item} className="mb-30">
          <div className="define-width mt-20">
            <Typography.Text
              className={isToday(item) ? 'today-text' : 'upcoming-text'}
            >
              {formatDate(item)}
            </Typography.Text>
          </div>
          {!screens.md ? (
            groupedEvents[item].map((event, index) => {
              const uniqueDomains: string[] = [];

              event.external_domains.forEach((email: string) => {
                const domain = email.split('@')[1];
                if (
                  domain &&
                  domain !== 'gmail.com' &&
                  !uniqueDomains.includes(domain)
                ) {
                  uniqueDomains.push(domain);
                }
              });
              return (
                <div className="meetings-list-row" key={index}>
                  <Row>
                    {uniqueDomains
                      .slice(0, 2)
                      .map((domain: string, key: number) => (
                        <img
                          key={key}
                          alt="example"
                          src={`https://logo.clearbit.com/${domain}`}
                          className="company-logos"
                        />
                      ))}
                  </Row>
                  <Row align="middle">
                    <Col span={22}>
                      <Typography.Text className="fw-500">
                        {' '}
                        {event.summary}
                      </Typography.Text>
                    </Col>
                    <Col span={2}>
                      <Tooltip
                        title={
                          event.event_summary_id
                            ? 'View prep note'
                            : 'Generate prep note'
                        }
                      >
                        <Button
                          type="text"
                          className="p-0"
                          onClick={() =>
                            paymentStatus === 'expired' ||
                            paymentStatus === 'ended'
                              ? setShowEndSubscriptionModal(true)
                              : generateMeetingThenRedirect(event)
                          }
                        >
                          {createLoader === event.meeting_id ? (
                            <LoadingOutlined style={{ fontSize: '18px' }} />
                          ) : event.event_summary_id ? (
                            <img src={prepNoteIcon} alt="prepnote" />
                          ) : (
                            <img
                              src={summaryGeneratedIcon}
                              alt="summarygenerated"
                            />
                          )}
                        </Button>
                      </Tooltip>
                    </Col>
                  </Row>
                  <Row className="fs-12">
                    {formatTimeToLocal(event.start_time)} -
                    {formatTimeToLocal(event.end_time)}
                  </Row>
                </div>
              );
            })
          ) : (
            <div className="eventlist-group define-width">
              {groupedEvents[item].map((event, index) => {
                const uniqueDomains: string[] = [];

                event.external_domains.forEach((email: string) => {
                  const domain = email.split('@')[1];
                  if (
                    domain &&
                    domain !== 'gmail.com' &&
                    !uniqueDomains.includes(domain)
                  ) {
                    uniqueDomains.push(domain);
                  }
                });
                return (
                  <Row key={index} className="eventlist-row">
                    <div className="eventlist-wrapper">
                      <div className="eventlist-row-date">
                        <span className="start-time">
                          {formatTimeToLocal(event.start_time)}{' '}
                        </span>
                        <span className="end-time-font">
                          {formatTimeToLocal(event.end_time)}{' '}
                        </span>
                      </div>

                      <Divider type="vertical" className="event-divider" />

                      <Typography.Text className="event-title">
                        {event.summary}
                      </Typography.Text>
                    </div>
                    <div className="eventlist-row-extra">
                      <Tooltip
                        title={uniqueDomains?.map((domain, index) => (
                          <Row key={index} align="middle">
                            <span>{domain}</span>
                          </Row>
                        ))}
                      >
                        {uniqueDomains
                          .slice(0, 2)
                          .map((domain: string, key: number) => (
                            <img
                              key={key}
                              alt="example"
                              src={`https://logo.clearbit.com/${domain}`}
                              className="company-logos"
                            />
                          ))}
                      </Tooltip>
                      {uniqueDomains.length > 2 && (
                        <Button type="text" className="p-0">
                          <Tooltip
                            title={
                              <div className="logo-tooltip">
                                {uniqueDomains.map((domain, index) => (
                                  <Row key={index} align="middle">
                                    <img
                                      key={index}
                                      alt={`${domain}`}
                                      src={`https://logo.clearbit.com/${domain}`}
                                      className="tooltip-company-logo"
                                    />
                                    <span>{domain}</span>
                                  </Row>
                                ))}
                              </div>
                            }
                          >
                            <span>+ {uniqueDomains.length - 2}</span>
                          </Tooltip>
                        </Button>
                      )}
                      {uniqueDomains?.length > 0 && (
                        <Divider type="vertical" className="logo-divider" />
                      )}
                      <Tooltip
                        title={
                          event.event_summary_id
                            ? 'View prep note'
                            : 'Generate prep note'
                        }
                      >
                        <Button
                          type="text"
                          className="p-0"
                          onClick={() =>
                            paymentStatus === 'expired' ||
                            paymentStatus === 'ended'
                              ? setShowEndSubscriptionModal(true)
                              : generateMeetingThenRedirect(event)
                          }
                        >
                          {createLoader === event.meeting_id ? (
                            <LoadingOutlined style={{ fontSize: '18px' }} />
                          ) : event.event_summary_id ? (
                            <img src={prepNoteIcon} alt="prepnote" />
                          ) : (
                            <img
                              src={summaryGeneratedIcon}
                              alt="summarygenerated"
                            />
                          )}
                        </Button>
                      </Tooltip>
                    </div>
                  </Row>
                );
              })}
            </div>
          )}
        </div>
      );
    });
  }
  function FilterModal() {
    return (
      <Popover
        trigger="click"
        placement="bottom"
        open={open}
        onOpenChange={handleOpenChange}
        content={
          <>
            <Row align="middle" justify="space-between" className="mb-10">
              <Typography.Title className="fw-500 mb-0" level={5}>
                Filters
              </Typography.Title>
              <Row>
                <Button
                  className="p-0 fw-500"
                  type="text"
                  onClick={() => {
                    clearFilters();
                    setSelectedFilters(false);
                  }}
                >
                  Clear
                </Button>
                <Button
                  loading={filterLoader}
                  className="p-0 branding-color fw-500 ml-20"
                  type="text"
                  onClick={() => {
                    hide();
                    dispatch(
                      setStartDate(dateRange?.[0]?.format('YYYY-MM-DD'))
                    );
                    dispatch(setEndDate(dateRange?.[1]?.format('YYYY-MM-DD')));
                    dispatch(setCheckedDomain(selectedDomains));
                    setSelectedFilters(true);
                    localStorage.setItem(
                      'selectedDomains',
                      JSON.stringify(selectedDomains)
                    );
                    localStorage.setItem(
                      'dateRange',
                      JSON.stringify([
                        dateRange?.[0]?.format('YYYY-MM-DD'),
                        dateRange?.[1]?.format('YYYY-MM-DD'),
                      ])
                    );
                  }}
                >
                  Save Filter
                </Button>
              </Row>
            </Row>
            <Typography.Text type="secondary" className="mt-10">
              Date range
            </Typography.Text>

            <RangePicker
              value={dateRange}
              onChange={handleDateFilter}
              format={'YYYY-MM-DD'}
            />
            <div className="mt-20">
              <Typography.Text type="secondary">Company</Typography.Text>
              {domainLoading && (
                <Tooltip title="Fetching domains">
                  <LoadingOutlined className="ml-10" />
                </Tooltip>
              )}
              <Select
                disabled={domainLoading}
                mode="multiple"
                allowClear
                style={{ width: '100%' }}
                placeholder="Please select"
                value={selectedDomains}
                onChange={(e: string[]) => {
                  setSelectedDomains(e);
                }}
                options={options}
                optionRender={(option) => (
                  <Space>
                    <Checkbox
                      checked={selectedDomains.includes(String(option.value))}
                    >
                      <Row align="middle">
                        <img
                          src={`https://www.google.com/s2/favicons?domain=${option.label}&sz=64`}
                          alt=""
                          className="ml-5 domain-logo"
                        />
                        <span className="ml-5">{option.label}</span>
                      </Row>
                    </Checkbox>
                  </Space>
                )}
              ></Select>
            </div>
          </>
        }
      >
        <Row
          onClick={() => setFilterClick(true)}
          align="middle"
          className={
            !screens.md
              ? 'cursor-pointer'
              : selectedFilters
                ? 'cursor-pointer selected-filter'
                : 'cursor-pointer'
          }
        >
          <img src={filterIcon} alt="" />
          {!screens.xs && (
            <Typography.Text className="ml-10">Filters</Typography.Text>
          )}
        </Row>
      </Popover>
    );
  }

  const fetchData = async () => {
    try {
      const connected = await fetchProvider(users?.id as string);
      setConnectedProviders(connected);
    } catch (err) {
      console.error('Failed to fetch connected providers:', err);
    }
  };

  const fetchCalendarStatus = async () => {
    try {
      const status = await fetchConnectedIntegration(users?.id as string);
      setConnectedCalendar(status);
    } catch (err) {
      console.error('Failed to fetch connected providers:', err);
    }
  };

  const fetchCalendarEvents = async (searchText = '') => {
    setFilterLoader(true);
    setEventLoader(true);

    try {
      const startDateTime = dateChange
        ? dayjs(selectedDate).utc().startOf('day').toDate().toISOString()
        : startDate
          ? dayjs(startDate)
              .utc(true)
              .startOf('day')
              .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
          : dayjs(selectedMonth).utc().startOf('month').toISOString();

      const endDateTime = dateChange
        ? dayjs(selectedDate).utc().endOf('day').toDate().toISOString()
        : endDate
          ? dayjs(endDate)
              .utc(true)
              .endOf('day')
              .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
          : dayjs(selectedMonth).utc().endOf('month').toISOString();

      const timeMinimun = startDateTime;
      const timeMaximum = endDateTime;
      const userId = _.get(users, 'id');
      const domain = _.get(users, 'email', '').split('@')[1];
      const q = searchText
        ? searchText
        : !dateChange
          ? _.join([selectedDomains.map((email) => email.split('@')[1])], ',')
          : '';
      let path = '';
      // Fetch calendar events using the inputData
      if (startDate && endDate && searchText) {
        path = `events?timeMin=${timeMinimun}&timeMax=${timeMaximum}&userId=${userId}&q=${q}&domain=${domain}&provider=${connectedProviders}`;
      } else if (startDate && endDate) {
        path = `events?timeMin=${timeMinimun}&timeMax=${timeMaximum}&userId=${userId}&q=${q}&domain=${domain}&type=filter&provider=${connectedProviders}`;
      } else {
        path = `events?timeMin=${timeMinimun}&timeMax=${timeMaximum}&userId=${userId}&q=${q}&domain=${domain}&provider=${connectedProviders}`;
      }
      if (connectedProviders.includes(ssoType.microsoft)) {
        const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        path += `&timezone=${encodeURIComponent(userTimeZone)}`;
      }
      const response = await doGet(path);
      if (response?.data) {
        const calendarData = response.data;

        let filteredEvents = calendarData?.meetings || [];
        if (
          !dateChange &&
          !startDate &&
          dayjs(startDateTime).isSame(
            dayjs(selectedMonth).utc().startOf('month')
          )
        ) {
          const today = dayjs().startOf('day');
          filteredEvents = filteredEvents.filter((event: any) => {
            const eventDate = dayjs(event.start_time);
            const checkCurrentMonth =
              eventDate.month() === today.month() &&
              eventDate.year() === today.year();
            return checkCurrentMonth ? eventDate.isSameOrAfter(today) : true;
          });
        }
        setEventResponse({ ...calendarData, meetings: filteredEvents });
        dispatch(
          setMeetingEvents({ ...calendarData, meetings: filteredEvents })
        );
      }

      setFilterLoader(false);
      setEventLoader(false);
      // Reset states if there was a date change
      if (dateChange) {
        localStorage.removeItem('selectedDomains');
        localStorage.removeItem('dateRange');
        setDateRange([null, null]);
        setSelectedDomains([]);
        dispatch(setDateChange(false));
      }
    } catch (err: any) {
      if (
        err.error === 'Please re-authorize google calendar service' ||
        err.message === 'User not found'
      ) {
        setShowReAuthoRisedModal(true);
      }
      setFilterLoader(false);
      setEventLoader(false);
      message.error(err);
    }
  };

  const showExternalDomains = async (
    startMonthDate?: dayjs.Dayjs,
    endMonthDate?: dayjs.Dayjs
  ) => {
    try {
      setDomainLoading(true);
      const startDateTime = startMonthDate
        ? dayjs
            .utc(startMonthDate.format('YYYY-MM-DD')) // Parse as UTC at midnight
            .startOf('day')
            .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
        : startDate
          ? new Date(startDate).toISOString()
          : null;
      const endDateTime = endMonthDate
        ? dayjs
            .utc(endMonthDate.format('YYYY-MM-DD')) // Parse as UTC at midnight
            .endOf('day')
            .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
        : endDate
          ? new Date(endDate).toISOString()
          : null;

      const timeMinimun = startDateTime
        ? startDateTime
        : dayjs(selectedMonth).utc().startOf('month').toISOString(); // Ensure time is set
      const timeMaximum = endDateTime
        ? endDateTime
        : dayjs(selectedMonth).utc().endOf('month').toISOString();
      const userId = users?.id;
      const q = '';
      const domain = _.get(users, 'email', '').split('@')[1];

      const path = `events?timeMin=${timeMinimun}&timeMax=${timeMaximum}&userId=${userId}&q=${q}&domain=${domain}&provider=${connectedProviders}`;
      const response = await doGet(path);
      if (response.data) {
        setDomainDetails(response.data);
      }
      setDomainLoading(false);
    } catch (err: any) {
      setDomainLoading(false);
      message.error(err);
    }
  };

  useEffect(() => {
    if (users && connectedProviders?.length > 0) {
      fetchCalendarEvents();
    }
  }, [
    users,
    selectedDate,
    startDate,
    endDate,
    checkedDomain,
    connectedProviders,
  ]);

  useEffect(() => {
    fetchUsersID();
  }, []);

  useEffect(() => {
    if (users) {
      fetchData();
      fetchCalendarStatus();
    }
  }, [users]);

  useEffect(() => {
    if (users && filterClick && connectedProviders) {
      showExternalDomains();
    }
  }, [users, filterClick, connectedProviders]);

  const handleSSOAndCalendarModals = () => {
    const providerType = localStorage.getItem('sso_type');
    const isGoogleSSO = providerType === ssoType.Google;
    const isOIDCSSO = providerType === ssoType.oidc;
    const isNoSSO = !providerType;

    const isGoogleDisconnected = connectedCalendar?.google === 'disconnected';
    const isMicrosoftDisconnected =
      connectedCalendar?.microsoft === 'disconnected';

    if (isOIDCSSO && connectedProviders?.includes(ssoType.google)) {
      setEventLoader(false);
      setDisConnectGoogleModal(true);
    } else if (isGoogleSSO && connectedProviders?.includes(ssoType.microsoft)) {
      setEventLoader(false);
      setDisConnectMSModal(true);
    }

    if (isGoogleDisconnected && isMicrosoftDisconnected) {
      if (isOIDCSSO) {
        setOpenMSModal(true);
      } else if (isGoogleSSO) {
        setOpenGoogleConnectModal(true);
      } else if (isNoSSO) {
        setConnectModal(true);
      }
      setEventLoader(false);
    }
  };

  useEffect(() => {
    handleSSOAndCalendarModals();
  }, [connectedProviders, connectedCalendar]);

  return (
    <div className="eventlisting">
      <div className="define-width">
        {snoozeNotificationType?.notification_types?.length > 3 && (
          <Card className="notification-card">
            <Row className="notification-header">
              <div className="notification-content">
                <img src={Group} alt="image" />
                <Typography.Text className="notification-text">
                  {` Your notifications are currently snoozed until  ${dayjs(
                    snoozeNotificationType?.snooze_until
                  ).format('MMM DD, h:mm A')}`}
                </Typography.Text>
              </div>
              <Button
                loading={resumeLoader}
                type="link"
                className={
                  !screens.md
                    ? 'notification-button notification-margin'
                    : 'notification-button'
                }
                onClick={handleResumeNotifications}
              >
                {'Resume Notification'}
              </Button>
            </Row>
          </Card>
        )}
        <Row justify="space-between">
          <Row align="middle">
            <LeftOutlined
              onClick={handlePrevMonth}
              className="change-icons"
              style={{
                cursor: startDate || endDate ? 'not-allowed' : 'pointer',
                color: startDate || endDate ? '#c8c8c8' : 'black',
                stroke: startDate || endDate ? '#c8c8c8' : 'black',
              }}
            />
            <Button
              type="text"
              className="p-0 ml-10 mr-10"
              onClick={fetchTodayDate}
              disabled={!hasTodayEvents}
            >
              {!screens.md ? (
                <img src={WinrateImages.EventIcons.calendarIcon} />
              ) : (
                <span>Today</span>
              )}
            </Button>
            <RightOutlined
              onClick={handleNextMonth}
              className="change-icons"
              style={{
                cursor: startDate || endDate ? 'not-allowed' : 'pointer',
                color: startDate || endDate ? '#c8c8c8' : 'black',
                stroke: startDate || endDate ? '#c8c8c8' : 'black',
              }}
            />

            <Typography.Title level={5} className="fw-600 mb-0 ml-10">
              {startDate && endDate ? (
                <>
                  <span>
                    {startDate ? dayjs(startDate)?.format('DD, MMM, YYYY') : ''}{' '}
                  </span>{' '}
                  -
                  <span>
                    {endDate ? dayjs(endDate)?.format('DD, MMM, YYYY') : ''}
                  </span>
                </>
              ) : selectedDate ? (
                dayjs(selectedDate).format('MMM, YYYY')
              ) : (
                ''
              )}
            </Typography.Title>
            <Button type="text" onClick={() => fetchCalendarEvents()}>
              <img src={refreshIcon} />
            </Button>
          </Row>
          {FilterModal()}
        </Row>
      </div>
      <div className="define-width mt-20">
        <SearchComponent
          placeholder="Search"
          value={search}
          onChange={(value: string) => handleInputSearch(value)}
        />
      </div>
      {eventLoader ? (
        <div className="define-width mt-20">
          <Skeleton paragraph={{ rows: 5 }} active className="mt-10" />
        </div>
      ) : eventsResponse?.meetings?.length ? (
        Events()
      ) : searchText && eventsResponse?.meetings?.length === 0 ? (
        <div className="no-meeting-wrapper">
          <img src={WinrateImages.EventIcons.noSearchIcon} />
          <Typography.Title level={3} className="mt-10 no-meeting-found-text ">
            No <span className="no-meeting-found-text-color">Matches</span>{' '}
            Found{' '}
          </Typography.Title>
          <Typography.Text>
            Looks like we couldn’t find anything. Try refining your search.{' '}
          </Typography.Text>
        </div>
      ) : (
        <div className="no-meeting-wrapper">
          <img src={WinrateImages.EventIcons.noMeetingFoundIcon} />
          <Typography.Title level={3} className="mt-10 no-meeting-found-text ">
            No Meetings{' '}
            <span className="no-meeting-found-text-color">Found</span>
          </Typography.Title>
          <Typography.Text>
            No scheduled meetings. When new meetings are added,{' '}
          </Typography.Text>
          <Typography.Text>
            your prep notes will appear here automatically to keep you prepared.
          </Typography.Text>
          <SecondaryButton
            className="mt-10"
            text="Jump to Current Month"
            onClick={() => fetchCalendarEvents()}
          />
        </div>
      )}
      <SubscriptionExpiredModal
        setShowEndSubscriptionModal={setShowEndSubscriptionModal}
        showEndSubscriptionModal={showEndSubscriptionModal}
        users={users?.id}
      />
      <ReAuthoRisedModal
        setShowReAuthoRisedModal={setShowReAuthoRisedModal}
        showReAuthoRisedModal={showReAuthoRisedModal}
        users={users?.id}
      />
      <MSConnectModal
        disconnectGoogleModal={disconnectGoogleModal}
        setDisConnectGoogleModal={setDisConnectGoogleModal}
        users={users?.id}
      />
      <GoogleConnectModal
        disconnectMSModal={disconnectMSModal}
        setDisConnectMSModal={setDisConnectMSModal}
        users={users?.id}
      />
      <Modal
        open={openGoogleConnectModal}
        onCancel={() => setOpenGoogleConnectModal(false)}
        footer={null}
        className="calendar-sync-modal"
        width={600}
      >
        <Row justify="center">
          <img src={WinrateImages.EventIcons.calendarSyncIcon} />
        </Row>
        <Row justify="center" className="mt-20">
          <Typography.Title level={3} className="title-text fw-500">
            Calendar <span className="sync-text">Sync</span> Needed
          </Typography.Title>
        </Row>
        <Row justify="center">
          <Typography.Text>
            {' '}
            To continue syncing your calendar and receiving meeting
          </Typography.Text>
        </Row>
        <Row justify="center">
          <Typography.Text>
            {' '}
            prep notes, please reconnect your calendar.
          </Typography.Text>
        </Row>
        <Row justify="center" className="mt-20">
          <PrimaryButton
            text="Connect Google Calendar"
            onClick={() => connectCalendar(users?.id as string, 'event_list')}
          />
        </Row>
      </Modal>
      <Modal
        open={openMSModal}
        onCancel={() => setOpenMSModal(false)}
        footer={null}
        className="calendar-sync-modal"
        width={600}
      >
        <Row justify="center">
          <img src={WinrateImages.EventIcons.calendarSyncIcon} />
        </Row>
        <Row justify="center" className="mt-20">
          <Typography.Title level={3} className="title-text fw-500">
            Calendar <span className="sync-text">Sync</span> Needed
          </Typography.Title>
        </Row>
        <Row justify="center">
          <Typography.Text>
            {' '}
            To continue syncing your calendar and receiving meeting
          </Typography.Text>
        </Row>
        <Row justify="center">
          <Typography.Text>
            {' '}
            prep notes, please reconnect your calendar.
          </Typography.Text>
        </Row>
        <Row justify="center" className="mt-20">
          <PrimaryButton
            text="Connect Outlook Calendar"
            onClick={() => connectMS(users?.id as string, 'event_list')}
          />
        </Row>
      </Modal>
      <ConnectModal
        setConnectModal={setConnectModal}
        connectModal={connectModal}
        users={users?.id}
      />
    </div>
  );
};

export default EventList;
