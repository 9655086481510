import { useState, useEffect, useRef } from 'react';
import { Input, Button, Spin, Typography, Tooltip, Badge } from 'antd';
import {
  SendOutlined,
  LoadingOutlined,
  GlobalOutlined,
  CheckOutlined,
} from '@ant-design/icons';
import { fetchAuthSession } from 'aws-amplify/auth';
import { WinrateImages } from '../../../assets/Images';
import useWebsocket from '../../../hooks/useWebsocket';
import { ChatMD } from './ChatMD';
import { Message } from '../types';
import './ChatWrapper.less';

const { Text } = Typography;
const wsURL = process.env.REACT_APP_WS_URL || '';

const initializeClient = async () => {
  const currentSession = await fetchAuthSession();
  const idToken = currentSession.tokens?.idToken;

  return `${wsURL}?idToken=${idToken}`;
};

const ToolTranslator = (tool: string) => {
  switch (tool) {
    case 'web_searches':
      return 'Searching the web';
    case 'hubspot_search_domain':
      return 'Querying Hubspot';
    case 'salesforce_search_domain':
      return 'Querying Salesforce';
    case 'gmail_search':
      return 'Querying Gmail';
    case 'contact_linkedin_search':
      return 'Searching for contact';
    case 'company_lookup':
      return 'Searching for company';
    case 'company_technographics':
      return 'Searching for company tech stack';
    case 'company_employee_categories':
      return 'Searching for company employee categories';
    case 'company_customers':
      return 'Searching for company customers';
    case 'company_financials':
      return 'Searching for company financials';
    case 'company_enhance':
      return 'Searching detailed company info';
    default:
      return 'Working';
  }
};

const MessageTools = ({ tools }: { tools: any }) => {
  if (!tools) {
    return null;
  }

  const toolKeys = Object.keys(tools);
  return (
    <div className="message-tools">
      {toolKeys.map((key) => {
        const tool = tools[key];
        return (
          <div key={key} className="tool-status">
            <Text type="secondary">{ToolTranslator(tool.detail)}</Text>
            {tool.status === 'in_progress' ? (
              <Badge status="processing" />
            ) : (
              <CheckOutlined style={{ color: '#0A0' }} />
            )}
          </div>
        );
      })}
    </div>
  );
};

const MessageResponse = ({ message }: { message: Message }) => {
  const msgParts = message.message.split('\n');
  // remove last part
  const inProgressMsg = message.status === 'in_progress' ? msgParts.pop() : '';

  return (
    <div className="message-response">
      {/* <div className="bot-avatar"></div> */}
      <div>
        {message.status === 'in_progress' && (
          <div className="progress-msg">
            <Spin indicator={<LoadingOutlined spin />} />

            <MessageTools tools={message.tools} />
          </div>
        )}
        <ChatMD>{msgParts.join('\n')}</ChatMD>
        {message.status === 'in_progress' && (
          <div className="in_progress">{inProgressMsg}</div>
        )}
      </div>
    </div>
  );
};

export const ChatWrapper = ({
  noteId,
  tools,
}: {
  noteId: string;
  tools: string[];
}) => {
  const localContainer = useRef<HTMLDivElement | null>(null);
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState<Message[]>([]);
  const {
    socket,
    status,
    connected,
    setMessages: setMessagesObj,
    messages: messagesObj,
  } = useWebsocket(initializeClient);

  useEffect(() => {
    if (messages.length > 0) {
      scrollDown();
    }
  }, [messages, messagesObj]);

  const sendMessage = (message: string) => {
    if (!message.trim()) {
      return;
    }
    // create id based on timestamp
    const id = new Date().getTime();
    if (socket && connected) {
      const payload = {
        id,
        noteId,
        action: 'chat',
        type: 'user',
        message,
      };
      setMessages((prev) => [
        ...prev,
        {
          id: id.toString(),
          type: 'user',
          message,
        },
      ]);
      setMessagesObj((prev: any) => ({
        ...prev,
        [id]: {
          id: id.toString(),
          type: 'assistant',
          status: 'in_progress',
          message: '',
        },
      }));
      socket.send(JSON.stringify(payload));
    }
  };

  const scrollDown = () => {
    if (localContainer.current) {
      localContainer.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
    }
  };

  return (
    <>
      <div className="chat-wrapper">
        {messages.map((message) => (
          <div key={`message-${message.id}`} className="message">
            <div className={`message-content ${message.type}`}>
              <div className="message-bubble">{message.message}</div>
            </div>
            <MessageResponse message={messagesObj[message.id]} />
          </div>
        ))}
      </div>
      <div
        style={{ margin: '10px 0px', height: '100px' }}
        ref={localContainer}
      />
      <div className={`chat-input ${connected ? '' : 'connected'}`}>
        <div className="chat-input-border">
          <div className="chat-input-content">
            <Input
              disabled={!connected}
              size="large"
              variant="borderless"
              placeholder={
                !connected
                  ? `${status.charAt(0).toUpperCase() + status.slice(1)}...`
                  : 'Ask a question...'
              }
              allowClear={false}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              onPressEnter={() => {
                sendMessage(message);
                setMessage('');
              }}
            />
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {tools.includes('web') && (
                  <Tooltip title="Web search is enabled">
                    <Button
                      icon={<GlobalOutlined style={{ color: '#888' }} />}
                      type="text"
                    ></Button>
                  </Tooltip>
                )}
                {/* {tools.includes('gmail') && (
                  <Tooltip title="Gmail is enabled">
                    <Button
                      icon={
                        <img
                          src={WinrateImages.Icons.gmailIcon}
                          alt="Salesforce logo"
                          width="18px"
                        />
                      }
                      type="text"
                    ></Button>
                  </Tooltip>
                )}
                {tools.includes('gcalendar') && (
                  <Tooltip title="Google calendar is enabled">
                    <Button
                      icon={
                        <img
                          src={WinrateImages.Auth.google_cal}
                          alt="Salesforce logo"
                          width="18px"
                        />
                      }
                      type="text"
                    ></Button>
                  </Tooltip>
                )} */}
                {tools.includes('hubspot') && (
                  <Tooltip title="Hubspot is enabled">
                    <Button
                      icon={
                        <img
                          src={WinrateImages.Icons.hubspotIcon}
                          alt="Hubspot logo"
                          width="18px"
                        />
                      }
                      type="text"
                    ></Button>
                  </Tooltip>
                )}
                {tools.includes('salesforce') && (
                  <Tooltip title="Salesforce is enabled">
                    <Button
                      icon={
                        <img
                          src={WinrateImages.Auth.salesForce}
                          alt="Salesforce logo"
                          width="18px"
                        />
                      }
                      type="text"
                    ></Button>
                  </Tooltip>
                )}
              </div>
              <Button
                type="text"
                shape="circle"
                size="large"
                className="btn-gradient"
                disabled={!connected}
                icon={<SendOutlined />}
                onClick={() => {
                  sendMessage(message);
                  setMessage('');
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
