import { ShareAltOutlined } from '@ant-design/icons';
import { Button, FloatButton, message } from 'antd';
import { WinrateImages } from '../../assets/Images';

const ShareNote = () => {
  const handleShare = () => {
    const subject = encodeURIComponent('Check out this link!');
    const body = encodeURIComponent(
      `Check out this link: ${window.location.href}`
    );
    const gmailUrl = `https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=&su=${subject}&body=${body}`;
    window.open(gmailUrl, '_blank');
  };

  const handleCopy = () => {
    const textToCopy = window.location.href;
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        message.success('URL copied to clipboard!');
      })
      .catch((err) => {
        message.error('Failed to copy text.');
        console.error('Error copying text: ', err);
      });
  };

  const handleShareViaSMS = () => {
    const phoneNumber = '';
    const message = encodeURIComponent(
      `Check out this link: ${window.location.href}`
    );
    const smsUrl = `sms:${phoneNumber}?body=${message}`;

    // Open SMS app
    window.open(smsUrl);
  };

  return (
    <FloatButton.Group
      trigger="click"
      type="primary"
      style={{ insetInlineEnd: 24 }}
      icon={<ShareAltOutlined />}
    >
      <div className="float-style">
        <Button
          type="text"
          onClick={handleShareViaSMS}
          className="p-0 icon-btn mb-10"
        >
          <img src={WinrateImages.Icons.textMessageIcon} alt="sms" />
        </Button>
        <Button
          type="text"
          onClick={handleShare}
          className="p-0 icon-btn mb-10"
        >
          <img src={WinrateImages.Icons.mailIcon} alt="mail" />
        </Button>
        <Button type="text" onClick={handleCopy} className="p-0 icon-btn">
          <img src={WinrateImages.Icons.copyIcon} alt="copy" />
        </Button>
      </div>
    </FloatButton.Group>
  );
};

export default ShareNote;
