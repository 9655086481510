import { Button, Modal, Row, Typography } from 'antd';
import PrimaryButton from '../common/PrimaryButton';
import { useState } from 'react';
import { doDelete } from '../sdk/Events';
import { connectMS } from '../utils/IntegrationHelper';

const MSConnectModal = ({
  disconnectGoogleModal,
  setDisConnectGoogleModal,
  users,
}: {
  disconnectGoogleModal: boolean;
  setDisConnectGoogleModal: (open: boolean) => void;
  users?: any;
}) => {
  const [connectLoading, setConnectLoading] = useState(false);

  const handleMSConnect = async () => {
    try {
      setConnectLoading(true);
      const path = `setting/google/${users}`;
      await doDelete(path)
        .then(() => {
          connectMS(users, 'notification');
        })
        .catch((err) => console.error(err));
      setConnectLoading(false);
    } catch (err) {
      setConnectLoading(false);
      console.error(err);
    }
  };

  return (
    <Modal
      open={disconnectGoogleModal}
      onCancel={() => setDisConnectGoogleModal(false)}
      footer={null}
    >
      <Row justify="center">
        <Typography.Title level={4}>
          Update Calendar Integration
        </Typography.Title>
      </Row>
      <Row justify="center" className="mt-20">
        <Typography.Text className="text-center">
          You are currently signed in with Microsoft, but your calendar is
          connected to Google. To use the same account for both, please switch
          your calendar connection to Microsoft
        </Typography.Text>
      </Row>
      <Row justify="center" className="mt-20">
        <PrimaryButton
          text="Switch Calendar"
          onClick={handleMSConnect}
          loading={connectLoading}
          className="switch-btn"
        />
      </Row>
      <Row justify="center" className="mt-10">
        <Button
          type="text"
          onClick={() => setDisConnectGoogleModal(false)}
          className="later-btn"
        >
          I&apos;ll Do it Later
        </Button>
      </Row>
    </Modal>
  );
};

export default MSConnectModal;
